import React from "react";
import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import { defaultOptions } from "utils/chartOptions";
import useIntervalActions from "hooks/useIntervalActions/useIntervalActions";
import GraphLayout from "components/GraphLayout/GraphLayout";
import PropTypes from "prop-types"

export default function PaymentTrend({amount}) {
    const { selectedPeriod, periods } = useIntervalActions()

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                data: [500, 1900, 5000, 900, 5200, 1000],
                borderDashOffset: [10],
                fill: true,
                backgroundColor: '#fff9b320',
                borderColor: '#23CE6B',
                pointHitRadius: 10,
                lineTension: '0.4',
                pointBackgroundColor: '#23CE6B',
                pointRadius: 0,
                borderCapStyle: 'round',
            },
        ],
    }

    return (
        <GraphLayout
            title={<>Payment Trend <span className="text-16 text-neutral_500 font-semibold">{amount}</span></>}
            id='paymentTrend'
            actions={periods}
            selectedAction={selectedPeriod}
        >
            <ChartWrapper
                type="line"
                options={defaultOptions}
                data={data}
                className="h-[324px]"
            />
        </GraphLayout>
    )
}

PaymentTrend.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };