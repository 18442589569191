import React from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import AppLayout from '../components/Layouts/AppLayout';
import Login from 'pages/Auth/Login/Login';
import VerifyEmail from 'pages/Auth/VerifyEmail/VerifyEmail';
import ForgotPassword from 'pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword';
import Dashboard from 'pages/Dashboard/Dashboard';
import AuditTrail from 'pages/AuditTrail/AuditTrail';
import Communities from 'pages/CustomerManagement/Communities/Communities';
import Settings from 'pages/Settings/Settings';
import UserManagement from 'pages/UserManagement/UserManagement';
import ViewSingleUser from 'pages/UserManagement/ViewSingleUser/ViewSingleUser';
import ViewSingleCommunity from 'pages/CustomerManagement/Communities/ViewSingleCommunity/ViewSingleCommunity';
import ViewSingleDriver from 'pages/CustomerManagement/Communities/ViewSingleCommunity/components/Drivers/ViewSingleDriver/ViewSingleDriver';

const router = createBrowserRouter([
    {
        path: "",
        element: <Login />,
    },
    {
        path: "verify-account",
        element: <VerifyEmail />
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "create-password",
        element: <ResetPassword />
    },
    {
        path: "reset-password",
        element: <ResetPassword />
    },
    {
        path: "/",
        errorElement: <div>Something went wrong</div>,
        element: (
            <AppLayout>
                <Outlet />
            </AppLayout>
        ),
        children: [
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: "/settings",
                element: <Settings />,
            },
            {
                path: "/user-management",
                element: <UserManagement />,
            },
            {
                path: "/user-management/:id",
                element: <ViewSingleUser />,
            },
            {
                path: "/audit-trail",
                element: <AuditTrail />,
            },
            {
                path: "/customer-management/communities",
                element: <Communities />,
            },
            {
                path: "/customer-management/communities/:id",
                element: <ViewSingleCommunity/>,
            },
            {
                path: "/customer-management/communities/:id/driver/:driver_id",
                element: <ViewSingleDriver />,
            }
        ]
    },
    {
        path: "*",
        element: <div>Page Not Found!</div>
    }
]);

export { router };
