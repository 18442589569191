import React from "react"
import Navbar from "../../components/Navbar/Navbar"

export default function Dashboard() {
    return (
        <div>
            <Navbar title="Dashboard" />
            <div className="app-body flex justify-center items-center">
                <p className="text-[30px] font-mulish font-bold">COMING SOON</p>
            </div>
        </div>
    )
}