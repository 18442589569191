import { createSlice } from '@reduxjs/toolkit';
import { editCommunities, getCommunities } from './action';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  communities: {},
  selectedCommunity: {}
};

export const communities = createSlice({
  name: 'communities',
  initialState,
    reducers: {
    setSelectedCommunity: (state = initialState, action) => {
      state.selectedCommunity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunities.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.communities = action?.payload?.data;
      })
      .addCase(editCommunities.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.selectedCommunity = {...state.selectedCommunity, ...action?.payload?.data }
      })
  },
});

// // Selectors
const selectCommunities = (state) => state.communities;

// //Reducers and actions
export const {setSelectedCommunity} = communities.actions

// //App Redux State
export const useCommunityState = () => useAppSelector(selectCommunities);

export default communities.reducer;
