import StatCard from 'components/StatCard/StatCard';
import React from 'react';
import { ReactComponent as Money4 } from 'assets/icons/money-4.svg';
import { ReactComponent as Battery } from 'assets/icons/battery.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import TotalDistancePerDay from './components/TotalDistancePerDay';
import useIntervalActions from 'hooks/useIntervalActions/useIntervalActions';

const Telemetry = () => {
  const {selectedPeriod:distanceFilter , periods: distanceCoveredFilter} = useIntervalActions()
  const {selectedPeriod: chargeFilter, periods: chargePaymentFilter} = useIntervalActions()

  return (
    <div className="mt-4 border border-neutral_100 rounded">
      <p className="py-4 px-6 border-b border-neutral_100 font-semibold text-neutral_700">Battery status Analytics</p>
      <div className="">
        <div className="grid grid-cols-3 gap-2 px-6 py-4 pb-6 ">
          <div className="border border-neutral_100 px-6 py-4 rounded">
            <Battery />
            <p className="text-12 text-neutral_500 font-mulish font-normal mb-[8px]">Battery state of charge</p>
            <p className="text-[28px] text-neutral_500 font-extrabold mb-[8px]">50 %</p>
            <p className="text-12 font-normal text-primary_green_800">Estimate 50% (4 hrs range)</p>
          </div>
          <StatCard
            title="Total charge payments made"
            icon={<Money4 />}
            countVariant="text-[28px] font-extrabold"
            count={`2,500,000km`}
            id='totalChargePayment'
            hint={true}
            actions={distanceCoveredFilter}
            selectedAction={distanceFilter}
            rate="increase"
          />
          <StatCard
            title="Total distance covered per day"
            icon={<Money4 />}
            id='totalDistance'
            countVariant="text-[28px] font-extrabold"
            count={`300,000km`}
            actions={chargePaymentFilter}
            hint={true}
            description={<span className="text-primary_red_900">Today 15th Mar, 2024</span>}
            selectedAction={chargeFilter}
          />
        </div>
          <div className='border-b border-neutral_100 pb-2 px-6'>
            <p className='mb-2 font-semibold text-neutral_700'>Battery current location</p>
            <div className='grid grid-cols-12 border px-4 py-3 border-neutral_100 rounded items-center text-neutral_500 text-14'>
              <p className='col-span-5'>#BAT23054</p>
              <p className='col-span-5'>6391 Elgin St. Celina, Delaware...</p>
              <div className='flex justify-between col-span-2'>
                <p className='italic underline text-primary_red_500 cursor-pointer'>View on map</p>
                <ImageIcon/>
              </div>
            </div>
          </div>
        <div className='pt-10 px-6 py-4'>
          <TotalDistancePerDay/>
        </div>
      </div>
    </div>
  );
};

export default Telemetry;
