import React from "react";
import PropTypes from "prop-types"
import Actions from "components/Table/Actions/Actions";
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';

export default function GraphLayout({ title, children, actions, selectedAction, id, tabs, selectedTab }) {
    return (
        <div className="app-section py-[24px] mb-[16px]">
            <div>
                <div className="flex justify-between mb-[22px]">
                    <div className="flex items-center">
                        <p className="text-14 text-neutral_400">{title}</p>
                        {tabs?.length ?
                            <div className="ml-[24px] flex">
                                {tabs?.map((t) => (
                                    <button
                                        key={t.name}
                                        className={`text-16 font-normal mr-[24px] px-[8px] ${selectedTab === t.name ? 'selected-tab' : 'unselected-tab'}`}
                                        onClick={t?.method}
                                    >
                                        {t.name}
                                    </button>
                                ))}
                            </div> : ''
                        }
                    </div>
                    <Actions
                        actions={actions}
                        id={id}
                        trigger={
                            <div className={`
                            flex justify-between items-center cursor-pointer rounded-[10px]
                            border bg-neutral_100 border-neutral_100 w-[102px] p-[8px]`}
                            >
                                <p className="text-14 text-neutral_500">{selectedAction}</p>
                                <ArrowDown stroke='#4F4F51' />
                            </div>
                        }
                    />
                </div>
                {children}
            </div>
        </div>
    )
}

GraphLayout.propTypes = {
    title: PropTypes.any,
    children: PropTypes.element,
    actions: PropTypes.array,
    selectedAction: PropTypes.string,
    tabs: PropTypes.array,
    selectedTab: PropTypes.string,
    id: PropTypes.string
}