import React from 'react'
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import PropTypes from 'prop-types';
import RedirectIfToken from 'HOC/RedirectIfToken';

const AuthLayout = ({ right }) => {
  return (
    <RedirectIfToken>
      <div className='bg-authBg min-h-[100vh] bg-no-repeat bg-cover lg:px-[136px] lg:py-24 pt-6 pb-24 flex flex-col lg:flex-row'>
        <div className='w-1/2'>
          <div className='lg:mb-0 mb-28'>
            <Logo className="w-[266px] h-[61px]" />
          </div>
          <div className='text-[44px] font-bold xl:mt-[171px] 2xl:mt-[250px] leading-[56px] lg:block hidden'>
            <p className='text-brand_white'>SUPER<span className='text-primary_red_500'>CHARGE</span></p>
            <p className='text-brand_white'>Power Moves <span className='text-primary_red_500'>Differently</span></p>
            <p className='text-24 leading-10 text-brand_white font-normal mt-2.5'>Power up your electric vehicles at our flagship charge site</p>
          </div>
        </div>
        <div className="md:mx-auto md:w-1/2 w-full flex justify-center items-center">
          {right && <div className="w-full md:max-w-[480px] px-6 md:mx-auto">{right}</div>}
        </div>
      </div>
    </RedirectIfToken>
  )
}

export default AuthLayout
AuthLayout.propTypes = {
  right: PropTypes.element
};