const { convertJsonToCsv } = require("./convertJsonToCSV");
const { formatDate } = require("./formatDate");

export const exportData = (data, tableTitle) => {
    const csvContent = convertJsonToCsv(data);
    // Create a Blob with the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create a download link and trigger a click
    const link = document.createElement('a');
    link.href = url;
    link.download = `${tableTitle}-${formatDate(new Date())}.csv`;
    link.click();
};