import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import CustomDateRangePicker from 'components/DateRangePicker/CustomDateRangePicker';
import Button from 'components/Button/Button';
import { statuses } from 'mocks/userManagement';
import Dropdown from 'components/Dropdown/Dropdown';

export default function Filter({ closeModal, setDateSelection, dateSelection, loading, filters, setFilters, filterFnc, rolesOptions, loadingRoles }) {
  return (
    <Modal title="Filter" position="modal-right" closeModal={closeModal}>
      <form className="relative">
        <div className="modal-right-body">
          <Dropdown
            id="status"
            name="status"
            optionType='status'
            label="Status"
            variant="mb-3"
            options={statuses}
            selected={filters?.status}
            onSelect={(data) => {
              setFilters({ ...filters, status: data.value });
            }}
          />
          <Dropdown
            id="role"
            name="role"
            label="Role"
            variant="mb-3"
            loading={loadingRoles}
            options={rolesOptions}
            optionType='search'
            selected={filters?.role}
            onSelect={(data) => {
              setFilters({ ...filters, role: data.value });
            }}
          />
          <CustomDateRangePicker
            label="Date range"
            initialState={dateSelection}
            onChange={(item) => setDateSelection([item.selection])}
          />
        </div>
        <div className="modal-right-button-container">
          <Button theme="primary" type="button" name="Filter" className="w-full" loading={loading} onClick={filterFnc}/>
        </div>
      </form>
    </Modal>
  );
}

Filter.propTypes = {
  closeModal: PropTypes.func,
  setDateSelection: PropTypes.func,
  dateSelection: PropTypes.array,
  loading: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  filterFnc: PropTypes.func,
  rolesOptions: PropTypes.array,
  loadingRoles: PropTypes.bool
};
