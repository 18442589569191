import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook';
import { getCountries } from './actions';

const initialState = {
  notification: {
    display: false,
    status: '',
    message: ''
  },
  countries: []
}

export const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    notify: (state, action) => {
      if (action?.payload.display === false) {
        state.notification.display = false;
        return
      }

      state.notification.display = true;
      state.notification.status = action?.payload?.status;
      state.notification.message = action?.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.fulfilled, (state, action) => {
        if (action.payload.data.length) state.countries = action?.payload?.data;
      })
  }
})

// Selectors
const selectGlobal = (state) => state.global;

//App Redux State
export const useGlobalState = () => useAppSelector(selectGlobal);

// Action creators are generated for each case reducer function
export const { notify } = global.actions

export default global.reducer