import React from "react";
import PropTypes from 'prop-types';
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function RequireAuth({ children }) {
  const { isLoggedIn, authToken } = useSelector(state => state.auth)
  let location = useLocation();

  // Redirect user to login page if user is not logged in
  if (!authToken && !isLoggedIn) return <Navigate to="/" state={{ from: location }} replace />

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.element
};
