import React from "react";
import PropTypes from "prop-types"

export default function Status({ status }) {
    const theme = () => {
        switch (status) {
            case 'active':
                return {
                    icon: <Pointer bgLarge="bg-[#CBF8DA]" bgSmall="bg-[#22C55E]" />,
                    container: "bg-[#F0FDF4] border-[#BBF7D0] text-[#22C55E]"
                }
            case 'deactivated':
                return {
                    icon: <Pointer bgLarge="bg-[#FEE2E2]" bgSmall="bg-[#EF4444]"  />,
                    container: "bg-[#FEF2F2] border-[#FECACA] text-[#EF4444]"
                }
            case 'inactive':
                return {
                    icon: <Pointer bgLarge="bg-[#E8E8E8]" bgSmall="bg-[#C2C2C5]"  />,
                    container: "bg-[#FDFDFD] border-[#C2C2C5] text-neutral_500"
                }
            default:
                return {
                    icon: <Pointer bgLarge="bg-[]" bgSmall="bg-[]"  />,
                    container: "bg-[#F0FDF4] border-[#22CC55E]"
                }
        }
    }

    return (
        <div className={`flex items-center border w-fit px-[8px] rounded-[18px] ${theme()?.container}`}>
            {theme()?.icon}
            <p className="text-14 font-normal capitalize">{status}</p>
        </div>
    )
}

const Pointer = ({ bgLarge, bgSmall }) => {
    return (
        <div className={`flex justify-center items-center w-[12px] h-[12px] rounded ${bgLarge} mr-[6px]`}>
            <div className={`w-[6px] h-[6px] rounded ${bgSmall}`}></div>
        </div>
    )
}

Status.propTypes = {
    status: PropTypes.string
}
Pointer.propTypes = {
    bgLarge: PropTypes.string,
    bgSmall: PropTypes.string
}