import React from 'react';
import PropTypes from 'prop-types';
import usePermission from 'hooks/usePermission/usePermission';

// Check if user has permission via a HOC
const Restricted = ({ module, permission, children }) => {
  const { hasPermission, hasModulePermission } = usePermission()

  if (!permission?.length && module && hasModulePermission(module)) return <>{children}</>;
  if (hasPermission(module, permission)) return <>{children}</>;

  return null
};

export default Restricted;

Restricted.propTypes = {
  permission: PropTypes.string,
  module: PropTypes.string,
  children: PropTypes.element,
};
