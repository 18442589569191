import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import Avatar from '../../components/Avatar/Avatar';
import { ReactComponent as Notification } from "../../assets/icons/notification-2.svg"
import { useAuthState } from 'store/modules/auth';
import { userInitals } from 'utils/userInitials';

export default function Navbar({ title }) {
    const url = ""
    const {loggedUser} = useAuthState()

    return (
        <header
            className={`
            w-full right-0 fixed top-0 z-10 bg-neutral_white flex items-center bg-brand_white
            justify-between px-[40px] py-[16px] border-b border-b-neutral_100 pl-[311px]
            `}
        >
            <h1 className="text-24 font-semibold text-neutral_500">{title}</h1>
            <div className="flex items-center">
                <div className='flex justify-center items-center w-[40px] h-[40px] bg-neutral_100 rounded-[16px]'>
                    <Notification />
                </div>
                <div className='h-[24px] border border-neutral_200 mx-[24px]'></div>
                <div className="flex">
                    <Avatar
                        url={url}
                        size={40}
                        alt={loggedUser?.first_name}
                        initials={userInitals(loggedUser?.first_name, loggedUser?.last_name)}
                        className="mr-[8px] w-[40px] h-[40px]"
                    />
                    <div className='flex items-center'>
                        <div className='mr-[8px] cursor-default'>
                            <p className="text-14 text-neutral_500 font-medium capitalize">{loggedUser?.first_name} {loggedUser?.last_name}</p>
                            <p className="text-12 text-neutral_400">{loggedUser?.email}</p>
                        </div>
                        <ArrowDown className='cursor-pointer' stroke='#4F4F51' />
                    </div>
                </div>
            </div>
        </header>
    );
}

Navbar.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};
