import Button from 'components/Button/Button'
import Input from 'components/Inputs/Input/Input'
import useAuth from 'hooks/fetch/useAuth';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const LoginForm = () => {
    const navigate = useNavigate()
    const {login_user, loading} = useAuth()

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const submitForm = (e) => {
        e.preventDefault();
        login_user(formData)
    };

    return (
        <section className='bg-brand_white rounded-xl px-10 py-20'>
            <p className='text-[34px] font-semibold leading-[44px] text-neutral_black mb-2'>Admin Log in</p>
            <p className='text-neutral_700 font-normal leading-6'>Kindly enter your log in credentials to continue</p>
            <form className='mt-8' onSubmit={submitForm}>
                <div className="mb-4">
                    <Input
                        label="Email"
                        type="text"
                        id="email"
                        name="email"
                        readOnly={loading}
                        value={formData?.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-8">
                    <Input
                        label="Password"
                        type="password"
                        id="password"
                        name="password"
                        readOnly={loading}
                        value={formData?.password}
                        onChange={handleChange}
                    />
                </div>
                <Button
                    theme="primary"
                    name="Login"
                    className="!rounded-[3px] w-full"
                    loading={loading} 
                    disabled={checkEmptyProperties(formData)}
                />
                <p className='underline text-primary_green_600 text-center mt-4 cursor-pointer' data-testid='forgotPassword' onClick={() => navigate('/forgot-password')}>Forgot password?</p>
            </form>
        </section>
    )
}

export default LoginForm