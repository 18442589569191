import AuthLayout from 'components/Layouts/AuthLayout'
import React from 'react'
import VerifyOtp from '../components/VerifyOtp/VerifyOtp'

const VerifyEmail = () => {
  return (
    <AuthLayout
        right={<VerifyOtp/>}
    /> 
  )
}

export default VerifyEmail