import { useState } from 'react';
import { PASSWORD_LENGTH, PIN_LENGTH, REGEX_EMAIL, REGEX_PASSWORD, REGEX_NAME, PHONE_NUMBER_LENGTH, REGEX_FULLNAME } from 'constants/auth';

export default function useInputValidate() {
  const [error, setError] = useState('');

  const checkPassword = ({ value }) => {
    if (value?.length < PASSWORD_LENGTH || !REGEX_PASSWORD.test(value)) {
      setError(`Password must be ${PASSWORD_LENGTH} or more characters and must be alphanumeric`);
      return false;
    }

    setError('');
    return true;
  };

  const checkEmail = ({ value }) => {
    if (!REGEX_EMAIL.test(value)) {
      setError('Please enter a valid email.');
      return false;
    }

    setError('');
    return true;
  };

  const checkName = ({ value }) => {
    if (!REGEX_NAME.test(value)) {
      if (value?.length < 2) {
        setError("Name must be more than 1 character")
        return false
      }
      setError("Name must contain only alphabets and cannot have spaces")
      return false
    }
    setError('')
    return true
  }

  const checkFullname = ({ value }) => {
    if (value?.length < 2) {
      setError("Name must be more than 1 character")
      return false
    }
    if (!REGEX_FULLNAME.test(value)) {
      setError("Name must contain only alphabets")
      return false
    }
    setError('')
    return true
  }

  const checkConfirmPassword = ({ name, value }) => {
    if (value?.length === 0) return false;
    if (document.getElementById(name)?.value === document.getElementById('password')?.value) {
      setError('');
      return true;
    }

    setError('Passwords do not match!');
    return false;
  };

  const checkPin = (value) => {
    if (value?.length !== PIN_LENGTH) return false;
    setError('');
    return true;
  };

  const checkPhoneNumber = ({ value }) => {
    if (!value) {
      setError('Phone number cannot be empty.')
      return false
    }
    if (value.charAt(0) === "0") {
      if (value.length > PHONE_NUMBER_LENGTH) {
        setError('Phone number should not be more than 11 digits.')
        return false
      }
      if (value.length < PHONE_NUMBER_LENGTH) {
        setError('Phone number should not be less than 11 digits.')
        return false
      }
      if (/^0\d{10}$/.test(value)) {
        setError('')
        return true
      }
    } else {
      if (value.length > (PHONE_NUMBER_LENGTH - 1)) return setError('Phone number should not be more than 10 digits.')
      if (value.length < (PHONE_NUMBER_LENGTH - 1)) return setError('Phone number should not be more than 10 digits.')
      if (/^[1-9]\d{9}$/.test(value)) {
        setError('')
        return true
      }
    }
  }

  const validate = ({ name, value }) => {
    switch (name) {
      case 'password':
        return checkPassword({ value });
      case 'firstName':
      case 'lastName':
      case 'first_name':
      case 'last_name':
        return checkName({ name, value });
      case 'contactPersonName':
      case 'communityName':
      case 'country':
      case 'state':
      case 'lga':
        return checkFullname({ name, value });
      case 'email':
      case 'contactPersonEmail':
        return checkEmail({ name, value });
      case 'confirmPassword':
      case 'confirm_password':
        return checkConfirmPassword({ name, value });
      case 'pin':
        return checkPin(value);
      case 'phoneNumber':
      case 'contactPersonPhoneNumber':
      case 'phone_number':
        return checkPhoneNumber({ value });
      default:
        setError('');
        return true;
    }
  };

  const validateFormData = (formData) => {
    let output = []
    Object.entries(formData).forEach((entry) => {
      const [key, value] = entry;
      const inputStatus = validate({ name: key, value })
      output.push(inputStatus)
    })
    return !output.includes(false)
  }

  return {
    error,
    setError,
    validate,
    checkPin,
    validateFormData
  };
}
