import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import useInputValidate from 'hooks/useInputValidate/useInputValidate';
import "./PhoneInput.css"

const Phone = ({ onChange, value, variant, disabled = false, label, showError }) => {
  const { error, validate, setError } = useInputValidate(true);
  const [enhancedPhoneNumber, setEnhancedPhoneNumber] = useState('')
  const countryRef = useRef(null);

  const formatPhoneNumber = (phoneNumber) => {
    setError('')
    let phone
    if (phoneNumber.charAt(0) === "0")
      phone = phoneNumber.slice(1)
    else
      phone = phoneNumber
    return phone
  }

  const handleChange = (val, country) => {
    setEnhancedPhoneNumber(val.slice(country.dialCode.length));
    countryRef.current = country;
  };

  useEffect(() => {
    // Perform side effects after the render phase
    if (countryRef?.current?.dialCode && onChange) {
      onChange({
        ...value,
        phoneNumber: formatPhoneNumber(enhancedPhoneNumber),
        countryCode: '+' + countryRef.current.dialCode,
      });
    }
  }, [enhancedPhoneNumber]);

  return (
    <div className={`flex flex-col ${variant || 'mb-6'}`}>
      <div className="relative">
        <label
          htmlFor='phone_number'
          className={`
            ${disabled ? 'bg-none' : 'bg-brand_white'} block
            text-14 text-neutral_500 mb-[4px] cursor-default ${variant}`}
        >
          {label}
        </label>
        <PhoneInput
          placeholder="Phone number"
          containerStyle={{
            width: '100%',
            borderRadius: '8px',
          }}
          inputProps={{
            disabled: disabled,
            id: 'phone_number',
          }}
          buttonStyle={{
            borderBottomLeftRadius: '8px',
            borderTopLeftRadius: '8px',
            borderRight: '0px',
            border: "none",
            height: "38px",
            right: "16px",
            top: "3px",
            backgroundColor: disabled ? '#F2F3F3' : '#ffffff',
          }}
          inputStyle={{
            borderRadius: '4px',
            width: '100%',
            color: '#424248',
            border: '1px solid #D7D7E3',
            height: '48px',
            fontFamily: ['inter'],
            fontSize: '14px',
            boxShadow: '',
            paddingLeft: "16px",
            backgroundColor: disabled ? '#F2F3F3' : '#ffffff',
          }}
          // disableCountryCode= {true}
          id="phone_number"
          name="phone_number"
          onChange={(value, country) => handleChange(value, country)}
          onBlur={() => validate({ name: 'phone_number', value: enhancedPhoneNumber })}
          value={`${value?.countryCode}${value?.phoneNumber}`}
          country={'ng'}
          countryCodeEditable={false}
          enableSearch
          required
          defaultMask='...-...-....'
          defaultErrorMessage="sfsfsfs"
        />
      </div>
      {showError && <p className={`text-primary_red_500 text-12 mt-1`}>{error}</p>}
    </div>
  );
};

export default Phone;
Phone.propTypes = {
  value: PropTypes.object,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  showError: PropTypes.bool
};
