import React from "react";
import PropTypes from 'prop-types';
import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "store/modules/auth";

export default function RedirectIfToken({ children }) {
  const { isLoggedIn, authToken } = useAuthState()
  let location = useLocation();

  // Redirect user to dashboard if user is logged in
  if (authToken && isLoggedIn) return <Navigate to="/user-management" state={{ from: location }} replace />

  return children
}

RedirectIfToken.propTypes = {
  children: PropTypes.element
};
