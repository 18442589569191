import React from "react";
import PropTypes from 'prop-types'
import { createPortal } from "react-dom";
import { ReactComponent as Warning } from "assets/svg/warning.svg";
import Button from "components/Button/Button";
import useUserManagement from "pages/UserManagement/useUserManagement";
import useUsers from "hooks/fetch/useUsers";

export default function ActivateDeactivateUser({ closeModal, userDetails, action }) {
    const {getAllUsers} = useUserManagement()
    const {deactivate_user, loading, edit_user} = useUsers()

    const deactivateUser = () => {
        const callback = () => {
            closeModal()
            getAllUsers()
        }
        action === 'Deactivate' ?
        deactivate_user(`/${userDetails?.user_id}`, callback) : 
        edit_user(`/${userDetails?.user_id}`, {status: 'active'}, callback)
    }

    return (
        <>
            {createPortal(
                <div className="overflow-hidden">
                    <div className="z-[500] overlay"></div>
                    <div className="modal modal-center bg-brand_white w-[381px] rounded-[16px] p-[24px]">
                        <Warning className="mx-auto mb-[24px]" />
                        <p className="text-20 text-center text-neutral_900 font-semibold mb-[10px]">{action} user?</p>
                        <p className="text-neutral_400 text-center px-4">Are you sure you want to {action.toLowerCase()} this user?</p>
                        <div className='mt-6 grid grid-cols-2 gap-4'>
                            <Button
                                theme="primary"
                                name="Cancel"
                                onClick={closeModal}
                            />
                            <Button
                                loading={loading}
                                theme="secondary"
                                onClick={deactivateUser}
                                name="Yes, Proceed"
                            />
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    )
}

ActivateDeactivateUser.propTypes = {
    closeModal: PropTypes.func,
    userDetails: PropTypes.object,
    action: PropTypes.string
}