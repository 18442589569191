import AuthLayout from 'components/Layouts/AuthLayout'
import React from 'react'
import ForgotPasswordForm from '../components/ForgotPasswordForm/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
    <AuthLayout
        right={<ForgotPasswordForm/>}
    /> 
  )
}

export default ForgotPassword