import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch/useTryCatch';
import { getUsers, deactivateUser, editUser } from 'store/modules/users/actions';

export default function useUsers() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest();
    const { tryCatch } = useTryCatch();
    const [loading, setLoading] = useState(false);

    const get_users = (payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: getUsers(payload), alert: false, callback })
                .finally(() => setLoading(false));
        });
    };

    const deactivate_user = (id, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: deactivateUser({ pathname: id }), callback })
                .finally(() => setLoading(false));
        });
    };

    const edit_user = (id, payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: editUser({ pathname: id, payload }), callback })
                .finally(() => setLoading(false));
        });
    };

    const invite_user = (payload, callback) => {
        const url = `/auth/invite-user`
        tryCatch(async () => {
            setLoading(true);
            makeAnonymousRequest({ url, method: "post", payload, callback })
                .finally(() => setLoading(false));
        });
    };

    const export_users = () => {
        const url = `/users?noLimit=true`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({ url, method: "get", alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    }

    return {
        loading,
        get_users,
        deactivate_user,
        edit_user,
        invite_user,
        export_users
    };
}
