import ChartWrapper from 'components/ChartWrapper/ChartWrapper'
import GraphLayout from "components/GraphLayout/GraphLayout";
import React, { useState } from 'react'
import { defaultOptions } from 'utils/chartOptions'

const TotalDistancePerDay = () => {
    const [distanceFilter, setDistanceFilter] = useState("Morning")

    const data = {
        labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                data: [500, 2000, 3000, 2100, 700, 2100, 3000, 600],
                borderDashOffset: [10],
                fill: true,
                backgroundColor: '#fff9b320',
                borderColor: '#23CE6B',
                pointHitRadius: 10,
                lineTension: '0.4',
                pointBackgroundColor: '#23CE6B',
                pointRadius: 0,
            },
        ],
    }

    const distanceFilters = [
        {
            name: "Morning",
            method: () => setDistanceFilter("Morning")
        },
        {
            name: "Afternoon",
            method: () => setDistanceFilter("Afternoon")
        },
        {
            name: "Night",
            method: () => setDistanceFilter("Night")
        }
    ]

    return (
        <GraphLayout
            title="Total distance covered per day"
            actions={distanceFilters}
            id='distancePerDay'
            selectedAction={distanceFilter}
        >
            <ChartWrapper
                type="line"
                options={defaultOptions}
                data={data}
                className="h-[324px]"
            />
        </GraphLayout>
    )
}

export default TotalDistancePerDay