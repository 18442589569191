import React, { Suspense, lazy } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import Navbar from "../../components/Navbar/Navbar"
import Spinner from "components/Spinner/Spinner"
import Restricted from "HOC/Restricted"
const RolesAndPermissions = lazy(() => import("./components/RolesAndPermissions/RolesAndPermissions"))

export default function Settings() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const category = searchParams.get("category")

    const tabs = [
        {
            title: "Profile information",
            component: <div className="flex justify-center items-center">
                <p className="text-[30px] font-mulish font-bold mt-[180px]">COMING SOON</p>
            </div>,
            category: "profile-information",
            permission: ""
        },
        {
            title: "Roles and Permissions",
            component: <RolesAndPermissions />,
            category: "roles-and-permissions",
            permission: "View role"
        }
    ]

    return (
        <div>
            <Navbar title="Settings" />
            <div className="app-body">
                <div className="mb-[16px]">
                    {tabs.map(t =>
                        <Restricted
                            key={t.title}
                            module={t?.title}
                            permission={t?.permission}
                        >
                            <button
                                className={`
                                text-16 font-normal mr-[24px]
                                ${category === t.category ? "selected-tab" : "unselected-tab"}`}
                                onClick={() => navigate(`?category=${t.category}`)}
                            >
                                {t.title}
                            </button>
                        </Restricted>
                    )}
                </div>
                <div>
                    <Suspense fallback={<Spinner />}>
                        {tabs.find(t => t.category === category)?.component}
                    </Suspense>
                </div>
            </div>
        </div>
    )
}