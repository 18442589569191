import React from "react";
import PropTypes from 'prop-types';
import { ReactComponent as Dots } from "assets/icons/dots-three-vertical.svg"
import useOutsideTouch from "hooks/useOutsideTouch/useOutsideTouch";

export default function Actions({ actions, id, item, trigger }) {
    const { ref, display, setDisplay } = useOutsideTouch()

    const styles = (action) => {
        const enhancedAction = action.toLowerCase().split(" ")
        if ((enhancedAction.includes("deactivate") && item.status === 'deactivated') ||
            (enhancedAction.includes("activate") && (item.status === 'active' || item.status === 'inactive'))
        ) {
            return 'hidden'
        }
        return 'block'
    }

    const toggleDisplay = (e) => {
        e.stopPropagation()
        setDisplay(!display)
    }

    return (
        <div className="relative">
            {trigger ?
                <div
                    onClick={toggleDisplay}
                    data-testid={`action-${id}`}
                >
                    {trigger}
                </div> :
                <Dots
                    data-testid={`action-${id}`}
                    className="cursor-pointer"
                    onClick={toggleDisplay}
                />
            }
            {display &&
                <div
                    ref={ref}
                    className="
                    text-14 text-neutral_500 font-normal absolute z-10 right-[0px] top-[20px]
                    py-[8px] px-[8px] overflow-auto bg-brand_white drop-shadow-xl w-max rounded-[8px]"
                >
                    {actions?.map((action) =>
                        <button
                            key={action.name}
                            data-testid={action.name}
                            onClick={(e) => {
                                e.stopPropagation()
                                setDisplay(false)
                                if (action.method) action.method(item)
                            }}
                            className={`${styles(action.name)}
                            w-full py-[8px] px-[10px] font-normal flex items-center justify-between cursor-pointer hide_tap
                            transition ease-in-out duration-500 hover:bg-[#F2F3F3] rounded-[8px]`}
                        >
                            {action.name}
                        </button>
                    )}
                </div>
            }
        </div>
    )
}

Actions.propTypes = {
    actions: PropTypes.array,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    item: PropTypes.object,
    trigger: PropTypes.node
};