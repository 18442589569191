import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import { getDrivers } from './actions';

const initialState = {
  drivers: {},
  selectedDriver: {}
};

export const drivers = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    setSelectedDriver: (state = initialState, action) => {
      state.selectedDriver = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.drivers = action?.payload?.data;
      })
  },
});

// Selectors
const selectDrivers = (state) => state.drivers;

//Reducers and actions
export const {setSelectedDriver} = drivers.actions

//App Redux State
export const useDriverState = () => useAppSelector(selectDrivers);

export default drivers.reducer;
