import AuthLayout from 'components/Layouts/AuthLayout'
import React from 'react'
import ResetPasswordForm from '../components/ResetPasswordForm/ResetPasswordForm'

const ResetPassword = () => {
  return (
    <AuthLayout
        right={<ResetPasswordForm/>}
    /> 
  )
}

export default ResetPassword