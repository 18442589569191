import React from "react"
import PropTypes from 'prop-types';
import Actions from "components/Table/Actions/Actions";
import { ReactComponent as ArrowUpBg } from "assets/icons/arrow-up-bg.svg";
import { ReactComponent as ArrowDownBg } from "assets/icons/arrow-down-bg.svg";
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';

export default function StatCard({ title, icon, count, hint, containerVariant, countVariant, actions, selectedAction, rate, description, id }) {
    return (
        <div className={`p-[16px] border border-neutral_100 rounded-[4px] ${containerVariant}`}>
            <div className="flex justify-between">
                <div>
                    {icon}
                    <p className="text-12 text-neutral_500 font-mulish font-normal mb-[8px]">{title}</p>
                </div>
                {actions?.length ?
                    <Actions
                        actions={actions}
                        id={id}
                        trigger={
                            <div className={`
                            flex justify-between items-center cursor-pointer rounded-[10px]
                            border bg-neutral_100 border-neutral_100 w-[102px] p-[8px]`}
                            >
                                <p className="text-14 text-neutral_500">{selectedAction}</p>
                                <ArrowDown stroke='#4F4F51' />
                            </div>
                        }
                    /> : ''}
            </div>
            <p className={`${countVariant || 'text-24 font-semibold'} text-neutral_500`}>{count ?? "..."}</p>
            {hint &&
                <div className="flex items-center mt-1">
                {
                    rate === 'decrease' ? <ArrowDownBg className="mr-[4px]"/> :
                    <ArrowUpBg fill="#55B948" className="mr-[4px]" />
                }
                    <p className={`text-12 font-mulish font-normal ${rate === 'increase' ? 'text-primary_green' : 'text-primary_red_500'}`}> {description || '-2.5% from previous month'}</p>
                </div>
            }
        </div>
    )
}

StatCard.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    rate: PropTypes.string,
    hint: PropTypes.bool,
    containerVariant: PropTypes.string,
    countVariant: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    actions: PropTypes.array,
    selectedAction: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};