import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./CustomDateRangePicker.css"
import { DateRange } from 'react-date-range';
import { formatDate } from 'utils/formatDate';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

export default function CustomDateRangePicker({ label, onChange, initialState }) {
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (initialState[0]?.endDate) setShow(false)
    }, [initialState[0]?.endDate])

    return (
        <div className='w-full'>
            {label &&
                <p className="bg-brand_white block text-14 text-neutral_500 mb-[4px] cursor-default">
                    {label}
                </p>
            }
            <div className='flex'>
                <button
                    data-testid="start"
                    type='button'
                    onClick={() => setShow(true)}
                    className='flex justify-between items-center h-[48px] px-[16px] w-full hide_tap rounded-[4px] border border-stroke_line mr-[16px]'
                >
                    <p className='text-neutral_600 text-14'>{initialState[0].startDate && formatDate(initialState[0].startDate)}</p>
                    <Calendar/>
                </button>
                <button
                    type='button'
                    className='flex justify-between items-center h-[48px] px-[16px] w-full hide_tap rounded-[4px] border border-stroke_line cursor-not-allowed'
                >
                    <p className='text-neutral_600 text-14'>{initialState[0].endDate && formatDate(initialState[0].endDate)}</p>
                    <Calendar/>
                </button>
            </div>
            {show &&
                <DateRange
                    editableDateInputs={true}
                    onChange={onChange}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={true}
                    ranges={initialState}
                />
            }
        </div>
    )
}

CustomDateRangePicker.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    initialState: PropTypes.array
}