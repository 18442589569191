import { drivers } from 'services/endpoints'
import { createCustomAsyncThunk } from 'utils/createThunk'

const getDrivers = createCustomAsyncThunk('users/getDrivers', 'get', drivers.drivers)
const createDriver = createCustomAsyncThunk('users/createDriver', 'post', drivers.create_driver)
const bulkUploadDrivers = createCustomAsyncThunk('users/bulkUploadDrivers', 'post', drivers.upload_drivers)
const editDriver = createCustomAsyncThunk('users/createDriver', 'patch', drivers.edit_driver)

export {
    getDrivers,
    createDriver,
    bulkUploadDrivers,
    editDriver
}