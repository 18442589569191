const base = '/auth'
const base2 = '/users'

export const auth = {
    login: `${base}/login`,
    verify_otp: `${base}/verify-otp`,
    forgot_password: `${base2}/forgot-password`,
    validate_reset_password_code: `${base2}/validate-reset-password-code`,
    create_password: `${base}/create-user-password`,
    resent_otp:`${base}/resend-otp`,
    refresh_token:`${base}/refresh`
};