import React, { Suspense, lazy } from "react"
import Navbar from "../../../components/Navbar/Navbar"
import { useNavigate, useSearchParams } from "react-router-dom"
import Spinner from "components/Spinner/Spinner"
const Overview = lazy(() => import("./components/Overview/Overview"))
const Analytics = lazy(() => import("./components/Analytics/Analytics"))

export default function Communities() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const category = searchParams.get("category")

    const tabs = [
        {
            title: 'Overview',
            component: <Overview />,
            category: 'overview'
        },
        {
            title: 'Analytics',
            component: <Analytics purpose="communities" />,
            category: 'analytics'
        }
    ];

    return (
        <div>
            <Navbar title="Communities" />
            <div className="app-body">
                <div className="flex justify-between mb-[24px]">
                    <div>
                        {tabs.map((t) => (
                            <button
                                key={t.title}
                                className={`text-16 font-normal mr-[24px] ${category === t.category ? 'selected-tab' : 'unselected-tab'}`}
                                onClick={() => navigate(`?category=${t.category}`)}
                            >
                                {t.title}
                            </button>
                        ))}
                    </div>
                </div>
                <Suspense fallback={<Spinner />}>
                    {tabs.find((t) => t.category === category)?.component}
                </Suspense>
            </div>
        </div>
    )
}