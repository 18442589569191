import { createSlice } from '@reduxjs/toolkit';
import { getRoles } from './actions';

const initialState = {
  roles: {},
};

export const roles = createSlice({
  name: 'roles',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.roles = action?.payload?.data;
      })
  },
});

export default roles.reducer;
