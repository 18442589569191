import React, { useMemo } from "react";
import Button from "components/Button/Button";
import Search from "components/Inputs/Search/Search";
import PropTypes from 'prop-types'
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg"
import { ReactComponent as CloseCircle } from "assets/icons/close-circle.svg"
import { ReactComponent as Export } from "assets/icons/export.svg"
import { formatDate } from "utils/formatDate";
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues";
import Restricted from "HOC/Restricted";

export default function Toolbar({
    title, data, searchPlaceholder, buttonName, filters, exportFunc, isExportingData, exportPermission,
    clearFilters, buttonFnc, filterFnc, customOption, handleSearch, module, buttonPermission
}) {
    const camelCaseToSpace = (input) => {
        return input.replace(/([A-Z])/g, ' $1').toLowerCase();
    }

    const enhancedFilters = useMemo(() => {
        const f = filters && Object.fromEntries(Object.entries(removeKeysWithEmptyValues(filters)).map(([key, value]) => {
            if (["fromDate", "toDate"].includes(key)) {
                return ["dateRange", `${formatDate(filters["fromDate"])} to ${formatDate(filters["toDate"])}`];
            }
            return [key, value]
        }))
        return filters ? Object.entries(f) : []
    }, [filters])

    return (
        <div data-testid="toolbar">
            <div className="flex justify-between items-center">
                <div className="flex items-center py-[16px]">
                    <h3 className="text-16 text-neutral_500 font-semibold mr-[24px]">{title}</h3>
                    <Search id="search" name="search" placeholder={searchPlaceholder} onChange={handleSearch} />
                </div>
                <div className="flex">
                    {!!data?.length &&
                        <>
                            <Button
                                name="Filter"
                                className="text-14 text-neutral_400 mr-[12px] h-[36px]"
                                icon={<FilterIcon className="mr-[6px]" />}
                                theme="transparent"
                                onClick={filterFnc}
                            />
                            <Restricted module={module} permission={exportPermission}>
                                <Button
                                    name={isExportingData ? "Exporting..." : "Export"}
                                    className="text-14 mr-[12px] h-[36px]"
                                    icon={<Export className="mr-[6px]" />}
                                    theme="secondary"
                                    onClick={exportFunc}
                                    disabled={isExportingData}
                                />
                            </Restricted>
                        </>
                    }
                    {buttonName &&
                        <Restricted module={module} permission={buttonPermission}>
                            <Button
                                name={buttonName}
                                className="text-14 text-brand_white h-[36px]"
                                theme="primary"
                                onClick={buttonFnc}
                            />
                        </Restricted>
                    }
                    <Restricted module={module} permission={buttonPermission}>
                        {customOption}
                    </Restricted>
                </div>
            </div>
            {enhancedFilters?.length ?
                <div className="flex text-14 mb-[30px]">
                    <div className="flex items-center mr-[32px]">
                        {enhancedFilters?.map(([key, value]) =>
                            <p key={key} className="pr-[8px] mr-[8px] capitalize-first">
                                <span className="text-neutral_500 font-semibold">{camelCaseToSpace(key)}:{" "}
                                </span><span className="text-neutral_400">{value}</span>
                            </p>
                        )}
                    </div>
                    <Button
                        name="Clear filter"
                        className="text-14 text-neutral_400 px-[16px] py-[9px]"
                        icon={<CloseCircle className="mr-[6px]" />}
                        theme="transparent"
                        onClick={clearFilters}
                    />
                </div> : <></>
            }
        </div>
    )
}

Toolbar.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    buttonName: PropTypes.string,
    filters: PropTypes.object,
    customOption: PropTypes.node,
    buttonFnc: PropTypes.func,
    filterFnc: PropTypes.func,
    handleSearch: PropTypes.func,
    clearFilters: PropTypes.func,
    exportFunc: PropTypes.func,
    isExportingData: PropTypes.bool,
    module: PropTypes.string,
    buttonPermission: PropTypes.string,
    exportPermission: PropTypes.string
}
