import React, { memo } from "react"
import PropTypes from 'prop-types';
import { Link, useLocation } from "react-router-dom"
import { ReactComponent as Logo } from "assets/svg/logo.svg"
import { ReactComponent as Dashboard } from "assets/icons/category.svg"
import { ReactComponent as User } from "assets/icons/user.svg"
// import { ReactComponent as Audit } from "assets/icons/note.svg"
import { ReactComponent as Settings } from "assets/icons/settings.svg"
// import { ReactComponent as Notification } from "assets/icons/notification.svg"
// import { ReactComponent as Buildings } from "assets/icons/buildings.svg"
import { ReactComponent as People } from "assets/icons/people.svg"
import { ReactComponent as Logout } from "assets/icons/logout.svg"
//import { ReactComponent as Profile } from "assets/icons/profile-1user.svg"
import { ReactComponent as Profile2 } from "assets/icons/profile-2user.svg"
import SubMenuOptions from "./SubMenuOptions/SubMenuOptions";
import "./Sidebar.css"
import { useDispatch } from "react-redux";
import { logout } from 'store/modules/auth/actions';
import Restricted from "HOC/Restricted";
import { modules } from "constants/module";
import usePermission from "hooks/usePermission/usePermission";

const Sidebar = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch();
    const { customerManagement, userManagement } =  modules
    const { hasPermission } = usePermission()

    const customerManagementMenu = [
        {
            name: "Communities",
            url: "/customer-management/communities?category=overview",
            active: "",
            icon: <People />,
            permitted: hasPermission(customerManagement, "View community")
        },
        // {
        //     name: "Individual",
        //     url: "",
        //     active: "",
        //     icon: <Profile />,
        //     permitted: true
        // },
        // {
        //     name: "Enterprise",
        //     url: "",
        //     active: "",
        //     icon: <Buildings />,
        //     permitted: true
        // }
    ]

    return (
        <aside className="fixed z-20 top-0 bg-brand_black h-screen w-[271px]">
            <div className="px-[24px] py-[16px] border border-b-neutral_50">
                <Logo className="w-[180px] h-[51px]" />
            </div>
            <div className="flex flex-col justify-between h-[90vh]">
                <div className="px-[24px] py-[20px] border-b-neutral_50">
                    <NavLink
                        pathname={pathname}
                        url="/dashboard"
                        name="Dashboard"
                        icon={<Dashboard />}
                    />
                    <Restricted module={userManagement} permission='View users'>
                        <NavLink
                            pathname={pathname}
                            url="/user-management"
                            permission= "View users"
                            name="User Management"
                            icon={<User />}
                        />
                    </Restricted>
                    {/* <NavLink
                        pathname={pathname}
                        url="/audit-trail"
                        name="Audit Trail"
                        icon={<Audit />}
                    /> */}
                    <Restricted module={customerManagement}>
                        <SubMenuOptions
                            title="Customer Management"
                            titleIcon={<Profile2 />}
                            titleUrl="/customer-management"
                            // active={regex.test("/customer-management")}
                            options={customerManagementMenu}
                        />
                    </Restricted>
                </div>
                <div className="px-[24px] py-[20px] border-b-neutral_50">
                    <NavLink
                        pathname={pathname}
                        url="/settings?category=profile-information"
                        name="Settings"
                        icon={<Settings />}
                    />
                    {/* <NavLink
                        pathname={pathname}
                        url="/notifications"
                        name="Notifications"
                        icon={<Notification />}
                    /> */}
                    <NavLink
                        pathname={pathname}
                        name="Log out"
                        onClick={() => dispatch(logout())}
                        icon={<Logout />}
                    />
                </div>
            </div>
        </aside>
    )
}

const NavLink = ({ name, icon, pathname, url, onClick }) => {
    const selected = url ? pathname.match(url?.split("/")[1]?.split("?")[0]) : ''

    return (
            <Link
                to={url}
                onClick={onClick}
                className={`
                flex items-center whitespace-nowrap px-[16px] py-[8px] rounded-[8px]
                mb-[8px] nav-list ${selected ? "nav-list-selected" : ""}
                `}
            >
                {icon}
                <p className="text-14 text-brand_white ml-[12px] font-normal">{name}</p>
            </Link>
    )
}

export default memo(Sidebar)

NavLink.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.element,
    pathname: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func
};