import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch/useTryCatch';
import { getDrivers, createDriver, editDriver, bulkUploadDrivers  } from 'store/modules/drivers/actions';

export default function useDriver() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest();
    const { tryCatch } = useTryCatch();
    const [loading, setLoading] = useState(false);

    const get_drivers = (id, payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: getDrivers({pathname: id, payload}), alert: false, callback })
                .finally(() => setLoading(false));
        });
    };

    const edit_driver = (id, payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: editDriver({pathname: id, payload}), callback })
                .finally(() => setLoading(false));
        });
    };

    const create_driver = (payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: createDriver({payload}), callback })
                .finally(() => setLoading(false));
        });
    };

    const bulk_upload_driver = (payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: bulkUploadDrivers({payload}), callback })
                .finally(() => setLoading(false));
        });
    };

    const check_bulk_upload_driver = (payload) => {
        const url = `/drivers/check-bulk-upload-data`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({url, method: "post", alert: false, payload}).then((res) => res)
                .finally(() => setLoading(false));
        });
    };

    const get_drivers_no_limit = (id, entityType) => {
        const url = `/drivers/all/${id}?noLimit=true&entityType=${entityType}`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({ url, method: "get", alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    }
    return {
        loading,
        get_drivers,
        get_drivers_no_limit,
        create_driver,
        check_bulk_upload_driver,
        bulk_upload_driver,
        edit_driver
    };
}
