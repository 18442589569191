export const users = {
    "users": [
        {
            "id": 1,
            "phone_number": "08098765432",
            "user_id": "user-49726b56d55311ee824c6b938f3b2405",
            "email": "chinedu@enyata.com",
            "first_name": "qore",
            "last_name": "superadmin",
            "verification_token": null,
            "role": 'Customer support',
            "verification_token_expiry_time": null,
            "password_reset_token": null,
            "password_reset_token_expiry_time": null,
            "status": "active",
            "device_token": null,
            "fcm_token": null,
            "password": "$2b$10$EXAzRiSL7C1FWTusjD1zserF7kE3yuDhhYq9Jem4jj98CzmXgW/t6",
            "created_at": "2024-02-27T09:33:38.605Z",
            "updated_at": "2024-02-27T09:33:38.605Z"
        },
        {
            "id": 2,
            "phone_number": null,
            "user_id": "user-e9c4d378da5d11eebf6ad38af4bb1586",
            "email": "joy@yopmail.com",
            "role": 'Customer success',
            "first_name": "joy",
            "last_name": "adeola",
            "verification_token": null,
            "verification_token_expiry_time": null,
            "password_reset_token": null,
            "password_reset_token_expiry_time": null,
            "status": "deactivated",
            "device_token": null,
            "fcm_token": null,
            "password": null,
            "created_at": "2024-03-04T19:32:18.385Z",
            "updated_at": "2024-03-05T10:19:30.110Z"
        },
        {
            "id": 3,
            "phone_number": null,
            "user_id": "user-673ffce8dad511eebf6e674d723cda8a",
            "email": "test@gmail.com",
            "first_name": "dayo",
            "last_name": "ashaolu",
            "verification_token": null,
            "verification_token_expiry_time": null,
            "password_reset_token": null,
            "password_reset_token_expiry_time": null,
            "status": "deactivated",
            "device_token": null,
            "fcm_token": null,
            "password": null,
            "created_at": "2024-03-05T09:47:39.018Z",
            "updated_at": "2024-03-05T10:19:59.296Z"
        }
    ],
    "page": 1,
    "limit": 10,
    "count": 3,
    "inactiveCount": 0,
    "deactivatedCount": 2,
    "activeCount": 1
}

export const statuses = [
    {
        name: "active",
        value: 'active'
    },
    {
        name: "deactivated",
        value: "deactivated"
    },
    {
        name: "inactive",
        value: "inactive"
    }
]