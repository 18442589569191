import { users } from 'services/endpoints'
import { createCustomAsyncThunk } from 'utils/createThunk'

const getUsers = createCustomAsyncThunk('users/getUsers', 'get', users.users)
const deactivateUser = createCustomAsyncThunk('users/deactivateUser', 'put', users.deactivate_user)
const editUser = createCustomAsyncThunk('users/editUser', 'patch', users.edit_user)

export {
    getUsers,
    deactivateUser,
    editUser
}