import React, { useState } from 'react';
import { ReactComponent as MoneyChange } from 'assets/icons/money-change.svg';
import ChartWrapper from 'components/ChartWrapper/ChartWrapper';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import Actions from 'components/Table/Actions/Actions';
import ChartItem from './ChartItem';
import { pieChartDataLabelsOptions } from 'utils/chartOptions';

export default function TotalPayment() {
  const [filter, setFilter] = useState('Monthly');

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false // Disable tooltips since we're displaying labels always
      },
      datalabels: pieChartDataLabelsOptions
    },
  };

  const data = {
    labels: ['Swaps', 'Charge'],
    datasets: [
      {
        data: [75, 25],
        backgroundColor: ['#2968B2', '#FF9466'],
        borderWidth: 0
      },
    ],
  };

  const paymentFilters = [
    {
      name: 'Morning',
      method: () => setFilter('Morning'),
    },
    {
      name: 'Afternoon',
      method: () => setFilter('Afternoon'),
    },
    {
      name: 'Night',
      method: () => setFilter('Night'),
    },
  ];

  return (
    <div className="app-section mb-[10px]">
      <div className="flex justify-between items-center mb-3.5">
        <p className="font-medium text-neutral_700">Total payment</p>
        <Actions
          actions={paymentFilters}
          id='totalPayment'
          trigger={
            <div
              className={`
                    flex justify-between items-center cursor-pointer rounded-[10px]
                    border bg-neutral_100 border-neutral_100 w-[102px] p-[8px]`}
            >
              <p className="text-14 text-neutral_500">{filter}</p>
              <ArrowDown stroke="#4F4F51" />
            </div>
          }
        />
      </div>
      <MoneyChange />
      <p className="text-12 text-neutral_400 font-inter font-normal mb-2">Total value of payments made</p>
      <p className="text-18 text-neutral_500 font-semibold mb-[37px]">NGN 120,000</p>
      <div>
        <ChartWrapper type="pie" options={chartOptions} data={data} className="h-[190px]" />
        <div className="app-section grid grid-cols-2 mt-[37px]">
          <ChartItem name="Swaps" count="NGN 65,000" color="bg-[#FF9466]" />
          <ChartItem name="Wallet" count="NGN 55,000" color="bg-[#2968B2]" />
        </div>
      </div>
    </div>
  );
}
