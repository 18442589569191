import Navbar from 'components/Navbar/Navbar';
import Spinner from 'components/Spinner/Spinner';
import React, { Suspense, lazy } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg"
import Button from 'components/Button/Button';
const Overview = lazy(() => import('./Overview/Overview'));
const Analytics = lazy(() => import('../components/Analytics/Analytics'));

const ViewSingleCommunity = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const category = searchParams.get("category")

    const tabs = [
        {
            title: 'Overview',
            component: <Overview />,
            category: 'overview',
            permission: "View community"
        },
        {
            title: 'Analytics',
            component: <Analytics purpose="community" />,
            category: 'analytics',
            permission: "View community"
        },
    ];

    return (
        <div>
            <Navbar title="Communities" />
            <div className="app-body">
                <div className="mb-[16px]">
                    <Button
                        name="Back"
                        icon={<ArrowLeft />}
                        className="h-[36px] border-0 !p-0 mb-3.5"
                        onClick={() => navigate(-1)}
                    />
                    {tabs.map((t) => (
                        <button
                            key={t.title}
                            className={`text-16 font-normal mr-[24px] ${category === t.category ? 'selected-tab' : 'unselected-tab'}`}
                            onClick={() => navigate(`?category=${t.category}`)}
                        >
                            {t.title}
                        </button>
                    ))}
                </div>
                <div>
                    <Suspense fallback={<Spinner />}>
                        {tabs.find((t) => t.category === category)?.component}
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default ViewSingleCommunity;
