import React from 'react';
import PropTypes from "prop-types"

const ChartItem = ({ name, count, color, className }) => {
  return (
    <div className={`flex ${className}`}>
      <div className={`text-12 text-neutral_400 w-[8px] h-[8px] mr-[8px] rounded-full ${color}`}></div>
      <div className="-mt-[5px]">
        <p className="text-12 text-neutral_400 mb-[8px]">{name}</p>
        <p className="text-14 text-neutral_600 font-semibold">{count}</p>
      </div>
    </div>
  );
};

export default ChartItem;

ChartItem.propTypes = {
  name: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  className: PropTypes.string,
};
