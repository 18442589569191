/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import "./Modal.css"

export default function Modal({
    children, title, className, styles, width, closeModal, containerVariant, authModal = false, position
}) {
    const pos = position === 'modal-right' ? `${position} h-screen` : 'modal-center'

    const close = () => {
        closeModal()
    }

    return (
        <>
            {createPortal(
                <div className="z-[500] overlay">
                    <div className={`modal ${pos} ${width || 'w-[398px]'} ${styles || ''} overflow-y-hidden`}>
                        <div className="flex w-full">
                            <div className={`${className || ''} ${!authModal ? 'bg-brand_white w-full' : ''} ${position ? 'rounded-t-2xl' : 'rounded-2xl'}`}>
                                {title && <div className={`${position === 'modal-right' ? 'border-b border-[#ECEEEE]' : ''} flex justify-between py-[17px] px-[24px]`}>
                                    <p className={`text-18 text-neutral_800 font-semibold capitalize-first`}>{title}</p>
                                    {closeModal && (
                                        <button
                                            onClick={close}
                                            data-testid="close-modal"
                                            className='text-[14px] text-neutral_600 font-medium bg-neutral_100 px-[12px] py-[4px] rounded-[100px] shrink-0'
                                        >
                                            Close
                                        </button>
                                    )}
                                </div>
                                }
                                <div
                                    className={`
                                        ${containerVariant || ''} px-6 py-4 
                                        ${position === "modal-right" ? 'md:h-[93vh] overflow-y-auto' : ''}
                                        `}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
}

Modal.propTypes = {
    closeModal: PropTypes.func,
    className: PropTypes.string,
    authModal: PropTypes.bool,
    styles: PropTypes.string,
    children: PropTypes.PropTypes.element,
    title: PropTypes.string,
    position: PropTypes.string,
    containerVariant: PropTypes.string,
    width: PropTypes.string
}