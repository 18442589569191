import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import { deactivateUser, editUser, getUsers } from './actions';

const initialState = {
  users: {},
  selectedUser: {}
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedUser: (state = initialState, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.users = action?.payload?.data;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.selectedUser = {...state.selectedUser, ...action?.payload?.data }
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.selectedUser = {...state.selectedUser, ...action?.payload?.data }
      })
  },
});

// Selectors
const selectUsers = (state) => state.users;

//Reducers and actions
export const {setSelectedUser} = users.actions

//App Redux State
export const useUserState = () => useAppSelector(selectUsers);

export default users.reducer;
