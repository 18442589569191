import AuthLayout from 'components/Layouts/AuthLayout'
import React from 'react'
import LoginForm from '../components/LoginForm/LoginForm'

const Login = () => {
  return (
    <AuthLayout
        right={<LoginForm/>}
    /> 
  )
}

export default Login