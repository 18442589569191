import { useState } from "react"

export default function useIntervalActions() {
    const [selectedPeriod, setSelectedPeriod] = useState("Monthly")

    const periods = [
        {
            name: "Weekly",
            method: () => setSelectedPeriod("Weekly")
        },
        {
            name: "Monthly",
            method: () => setSelectedPeriod("Monthly")
        },
        {
            name: "Yearly",
            method: () => setSelectedPeriod("Yearly")
        }
    ]
    return { selectedPeriod, periods }
}