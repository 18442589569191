import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import Status from 'components/Status/Status';
import Spinner from 'components/Spinner/Spinner';

export default function Dropdown({
  label,
  id,
  options,
  onSelect,
  optionType,
  loading,
  optionVariant,
  variant,
  disabled,
  containerVariant,
  width,
  optionContainerVariant,
  selected,
  children,
  readOnly = true,
  placeholder,
}) {
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState('');
  let ref = useRef();

  const listener = (e) => {
    if (!ref.current.contains(e.target)) setDisplay(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
  }, []);

  const handleChange = (e) => setSearch(e.target.value);

  const enhancedOptions = useMemo(() => {
    return !search ? options : options?.filter((option) => option?.name?.toLowerCase().includes(search?.toLowerCase()));
  }, [options, search]);

  return (
    <section
      ref={ref}
      className={variant}
    >
      {label && (
        <label
          htmlFor={id}
          className='text-neutral_500 mb-[4px] block text-14 cursor-default'
        >
          {label}
        </label>
      )}
      <div
        className={`
        relative cursor-pointer ${width} rounded
        border ${disabled ? 'bg-neutral_100 border-neutral_100' : 'bg-brand_white border-stroke_line'}
        `}
      >
        <div className="relative dropdown-container" onClick={() => !disabled && setDisplay(!display)}>
          {optionType === 'status' && selected ?
            <div className='h-12 rounded-lg px-4 py-3' data-testid="status-dropdown">
              <Status status={selected} />
            </div> :
            <input
              id={id}
              name={id}
              type="text"
              placeholder={loading ? 'Loading...' : (placeholder || '')}
              disabled={disabled}
              label={label}
              value={selected}
              data-testid={`${id}-dropdown`}
              aria-labelledby={id}
              readOnly={readOnly}
              autoComplete="off"
              className={`h-12 rounded-[8px] px-4 text-neutral_black text-14 w-full outline-0
            cursor-pointer font-normal hide_tap border-0 capitalize truncate
           `}
            />
          }
          <div className="h-full absolute top-0 right-0 flex items-center px-[18.5px] cursor-pointer hide_tap">
            {display ? <ArrowUp /> : <ArrowDown stroke='#4F4F51' />}
          </div>
        </div>
        {display && (
          <div
            data-testid="dropdown-modal"
            className={`${containerVariant} absolute left-[-1px] top-[100%] w-[100.3%] z-20 rounded border mt-1 border-stroke_line bg-brand_white`}
          >
            {optionType === "search" ?
              <div className="relative">
                <input
                  id="searh"
                  name="search"
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={handleChange}
                  className={`
                  h-[40px] pl-[40px] pr-[16px] text-neutral_600 text-14 w-full outline-0 hide_tap
                  border-b border-b-stroke_line 
                `}
                />
                <div className="flex items-center absolute top-0 left-[13.48px] cursor-pointer hide_tap h-full">
                  <SearchIcon className='w-[16px]'/>
                </div>
              </div> : ''
            }
            <div onClick={() => setDisplay(false)}>
              <div className={`${optionContainerVariant} max-h-[216px] overflow-auto bg-brand_white`}>
                {enhancedOptions?.map((option) => (
                  <div
                    key={option.name}
                    data-testid={option?.name}
                    onClick={() => onSelect(option)}
                    className={`${optionVariant} py-[11px] px-4 flex items-center justify-between
                    cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3]`}
                  >
                    <div className="flex items-center">
                      <p className="text-14 capitalize ">{option?.name}</p>
                    </div>
                  </div>
                ))}
                {children}
              </div>
              {loading && !enhancedOptions?.length && <Spinner />}
              {!loading && !enhancedOptions?.length && !children && <p className="text-center text-neutral_600 py-[20px]">No data found</p>}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.any,
  id: PropTypes.string,
  optionType: PropTypes.string,
  onSelect: PropTypes.func,
  variant: PropTypes.string,
  optionVariant: PropTypes.string,
  optionContainerVariant: PropTypes.string,
  options: PropTypes.array,
  loading: PropTypes.bool,
  containerVariant: PropTypes.string,
  selected: PropTypes.any,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};
