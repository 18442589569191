import React from 'react';
import { ReactComponent as MoneyChange } from 'assets/icons/money-change.svg';
import ChartWrapper from 'components/ChartWrapper/ChartWrapper';
import ChartItem from './ChartItem';
import { pieChartDataLabelsOptions } from 'utils/chartOptions';

const PaymentChannels = () => {
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false // Disable tooltips since we're displaying labels always
      },
      datalabels: pieChartDataLabelsOptions
    },
  };

  const data = {
    labels: ['Card', 'Wallet', 'USSD', 'Voucher'],
    datasets: [
      {
        data: [25, 35, 20, 20],
        backgroundColor: ['#2968B2', '#7B61FF', '#FF9466', '#F16A70'],
        borderWidth: 0
      },
    ],
  };

  return (
    <div className="app-section mb-[10px]">
      <p className="font-medium text-neutral_700 mb-4">Payment channels</p>
      <div className="mb-4">
        <MoneyChange />
        <p className="text-12 text-neutral_400 font-inter font-normal mt-1 mb-2">Total volume of payment</p>
        <p className="text-14 text-neutral_500 font-semibold">125</p>
      </div>
      <div className="mb-10">
        <MoneyChange />
        <p className="text-12 text-neutral_400 font-inter font-normal mt-1 mb-2">Total value of payment</p>
        <p className="text-14 text-neutral_500 font-semibold">NGN 365,000</p>
      </div>
      <div>
        <ChartWrapper type="pie" options={chartOptions} data={data} className="h-[190px]" />
        <div className="app-section grid grid-cols-1 gap-6 mt-[37px]">
          <div className='flex justify-between items-start'>
            <ChartItem name="Card" count="45" color="bg-[#2968B2]" />
            <p className="text14 font-inter font-semibold -mt-2">NGN 65,000</p>
          </div>
          <div className='flex justify-between items-start'>
            <ChartItem name="Wallet" count="40" color="bg-[#7B61FF]" />
            <p className="text14 font-inter font-semibold -mt-2">NGN 65,000</p>
          </div>
          <div className='flex justify-between items-start'>
            <ChartItem name="USSD" count="20" color="bg-[#FF9466]" />
            <p className="text14 font-inter font-semibold -mt-2">NGN 65,000</p>
          </div>
          <div className='flex justify-between items-start'>
            <ChartItem name="Voucher" count="20" color="bg-[#F16A70]" />
            <p className="text14 font-inter font-semibold -mt-2">NGN 65,000</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentChannels;
