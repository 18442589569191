import { useEffect, useState } from "react"
import useDebounce from "../useDebounce/useDebounce";

export default function useTable(limit) {
    const [searchValue, setSearchValue] = useState()
    const { debouncedValue } = useDebounce(searchValue);
    const [filterModal, setFilterModal] = useState(false)
    const [params, setParams] = useState({ page: 1, limit: limit ?? 10, sortBy: 'DSC' })
    const openFilterModal = () => setFilterModal(true)
    const closeFilterModal = () => setFilterModal(false)

    const changePage = (e) => setParams({ ...params, page: e })
    const selectLimit = (e) => setParams({ ...params, limit: e })
    const handleSearch = (e) => {
        if (e.target.value === "") {
            setParams({ page: 1, limit: limit ?? 10 })
            setSearchValue()
            return
        }
        setSearchValue(e.target.value.trim())
    }
    const handleSort = () => {
        if(params.sortBy === 'DSC') {
            setParams({...params, sortBy: 'ASC'})
        } else {
            setParams({...params, sortBy: 'DSC'})
        }
    }

    useEffect(() => {
        if (searchValue && debouncedValue !== undefined) {
            setParams({ ...params, search: debouncedValue })
        }
    }, [debouncedValue])

    return {
        params,
        setParams,
        changePage,
        selectLimit,
        handleSort,
        handleSearch,
        filterModal,
        searchValue,
        openFilterModal,
        closeFilterModal
    }
}