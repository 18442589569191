import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ name, className, icon, onClick, theme, textSize, disabled, type, loading }) {
  const themeStyle = () => {
    switch (theme) {
      case 'primary':
        return `text-brand_white ${disabled ? 'bg-[#F16A70]' : 'bg-primary_red_500 border-primary_red_500 hover:bg-[#EC3C43]'}`
      case 'secondary':
        return 'text-primary_red_500 bg-primary_red_50 border-primary_red_50'
      case 'transparent':
        return 'text-neutral_400 border-neutral_200'
      default:
        return ''
    }
  }

  return (
    <button
      className={`
            text-${textSize || 14} flex items-center justify-center rounded-[8px] px-[16px] py-[8px] 
            font-medium border ${themeStyle()} ${className}
            `}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
    >
      {loading ? (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="21" height="21" viewBox="0 0 128 128">
          <g>
            <path
              d="M75.4 126.63a11.43 11.43 0 01-2.1-22.65 40.9 40.9 0 0030.5-30.6 11.4 11.4 0 1122.27 4.87h.02a63.77 63.77 0 01-47.8 48.05v-.02a11.38 11.38 0 01-2.93.37z"
              fill={theme !== 'transparent' ? '#FFFFFF' : '#36A1C5'}
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 64 64"
              to="360 64 64"
              dur="1000ms"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      ) : (
        <>
          {icon}
          {name}
        </>
      )}
    </button>
  );
}

Button.propTypes = {
  name: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  textSize: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
