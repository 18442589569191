import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Empty } from "assets/svg/empty-table.svg";

export default function EmptyState({ caption, icon, className, width }) {
    return (
        <div className={`${className} flex flex-col justify-center items-center py-[24px] px-[16px] max-w-[479px] mx-auto`}>
            <div className="mx-auto mb-[16px]">{icon || <Empty className={`${width}`}/>}</div>
            <p className="text-16 text-neutral_500 font-normal text-center">{caption}</p>
        </div>
    )
}

EmptyState.propTypes = {
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    icon: PropTypes.element,
    width: PropTypes.string
}