import { useEffect, useState } from 'react';

export default function usePIN(numberOfPIN, ref) {
  const [otp, setOtp] = useState([]);

  useEffect(() => {
    let results = [];
    for (let i = 1; i <= numberOfPIN; i++) {
      results.push({
        key: i,
        value: '',
        isFocus: i === 1 ? true : false,
      });
    }

    setOtp(results);
  }, []);

  //Handler Function
  const onPress = (e, currentInput) => {
    if (currentInput.key > 1 && e.key === 'ArrowLeft') ref.current[currentInput.key - 1].focus();
    if (currentInput.key < 6 && e.key === 'ArrowRight') ref.current[currentInput.key + 1].focus();
    //Track for backspace click and change focus to last input
    if ( e.key === 'Backspace') {
      otp[currentInput.key - 1].value = ''; //Subtract by 1 to target current input
      setOtp([...otp]);
      if(currentInput.key > 1) {
        ref.current[currentInput.key - 1].focus(); //Update the focus of the next input
      } else if(currentInput.key === 1) {
        ref.current[currentInput.key].focus(); //Update the focus of the current input
      }
    }
  };

  const onPaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData;
    const pastedData = clipboardData?.getData('Text');

    const breakCopyCodeToArray = pastedData?.split('');

    otp.forEach((x, index) => {
      x.value = breakCopyCodeToArray[index];
      return x;
    });
    setOtp([...otp]);
    ref.current[numberOfPIN.length]?.focus();
  };

  const onChange = (e, currentInput) => {
    if (e.nativeEvent.inputType === 'insertText') {
      otp[currentInput.key - 1].value = e.target.value;
      setOtp([...otp]); //Update the value of the current input

      if (currentInput.key < numberOfPIN) {
        otp[currentInput.key].isFocus = true; // Set isFocus to true for the next input. Update the focus of the next input
        ref.current[currentInput.key + 1].focus();
        otp[currentInput.key - 1].isFocus = false; // Set isFocus to true for the next input
      }
    }
  };

  return {
    otp,
    onPress,
    onPaste,
    onChange,
    setOtp,
  };
}
