import ChartWrapper from "components/ChartWrapper/ChartWrapper";
import GraphLayout from "components/GraphLayout/GraphLayout";
import React, { useState } from "react";
import { defaultOptions } from "utils/chartOptions";

export default function PaymentVolumePerPaymentPeriod() {
    const [selectedFilter, setSelectedFilter] = useState("Morning")

    const data = {
        labels: ["7am", "8am", "9am", "10am", "11am", "12pm"],
        datasets: [
            {
                data: [600, 4800, 700, 4500, 700, 2100],
                borderDashOffset: [10],
                borderColor: '#9D96B8',
                pointBackgroundColor: '#9D96B8',
                pointRadius: 4,
                borderCapStyle: 'round',
            },
        ],
    }

    const filters = [
        {
            name: "Morning",
            method: () => setSelectedFilter("Morning")
        },
        {
            name: "Afternoon",
            method: () => setSelectedFilter("Afternoon")
        },
        {
            name: "Night",
            method: () => setSelectedFilter("Night")
        }
    ]

    return (
        <GraphLayout
            title="Payment volume per payment period"
            actions={filters}
            id='paymentVolume'
            selectedAction={selectedFilter}
        >
            <ChartWrapper
                type="line"
                options={defaultOptions}
                data={data}
                className="h-[324px]"
            />
        </GraphLayout>
    )
}