import Button from 'components/Button/Button';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Inputs/Input/Input';
import Modal from 'components/Modal/Modal';
import { statuses } from 'mocks/userManagement';
import Dropdown from 'components/Dropdown/Dropdown';
import Phone from 'components/Inputs/PhoneInput/PhoneInput';
import { useUserState } from 'store/modules/users';
import useUsers from 'hooks/fetch/useUsers';
import useUserManagement from 'pages/UserManagement/useUserManagement';
import { useParams } from 'react-router-dom';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import useInputValidate from 'hooks/useInputValidate/useInputValidate';
import { removeKeysWithEmptyValues } from 'utils/removeKeysWithEmptyValues';

const InviteUser = ({ closeModal, action, isFetchingRoles }) => {
  const { id } = useParams()
  const { rolesOptions, getAllUsers } = useUserManagement()
  const { selectedUser } = useUserState()
  const { validateFormData } = useInputValidate()
  const { invite_user, edit_user, loading } = useUsers()
  const [formData, setFormData] = useState({
    first_name: '',
    email: '',
    last_name: '',
    role: '',
    status: '',
    phone_number: ""
  });

  const [phone, setPhone] = useState({
    phoneNumber: '',
    countryCode: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (action === 'Edit') {
      setFormData({
        first_name: selectedUser?.first_name ?? '',
        last_name: selectedUser?.last_name ?? '',
        role: selectedUser?.role ?? '',
        status: selectedUser?.status ?? ''
      })
      setPhone({
        phoneNumber: selectedUser?.phone_number ?? '',
        countryCode: selectedUser?.country_code ?? ''
      })
    }
  }, [selectedUser, action])

  useEffect(() => {
    if (phone.phoneNumber || phone.countryCode) {
      setFormData({
        ...formData,
        country_code: phone.countryCode,
        phone_number: phone.phoneNumber
      })
    }
  }, [phone])

  const onSubmit = (e) => {
    e.preventDefault()
    const userId = selectedUser?.user_id ?? id
    const callback = () => {
      closeModal()
      getAllUsers()
    }
    if (action === "Invite") {
      const payload = removeSpecificKeys({ ...formData }, ["status"])
      invite_user(removeKeysWithEmptyValues(payload), callback)
    } else {
      const payload = removeSpecificKeys({ ...formData }, ["email"])
      edit_user(`/${userId}`, removeKeysWithEmptyValues(payload), callback)
    }
  }

  const disabled = useMemo(() => {
    if (action === "Invite") {
      const payload = removeSpecificKeys({ ...formData }, ["status", "phone_number"])
      return !validateFormData(payload) || !formData.role
    }
  }, [formData])

  return (
    <Modal title={`${action} user`} position="modal-right" closeModal={closeModal}>
      <form className="relative" onSubmit={onSubmit}>
        <div className="modal-right-body">
          <Input
            label="First name"
            type="text"
            name="first_name"
            className="mb-[16px]"
            readOnly={loading}
            id="first_name"
            value={formData?.first_name}
            onChange={handleChange}
          />
          <Input
            label="Last name"
            className="mb-[16px]"
            type="text"
            name="last_name"
            readOnly={loading}
            id="last_name"
            value={formData?.last_name}
            onChange={handleChange}
          />
          {action === 'Invite' && <Input
            label="Email address"
            type="email"
            className="mb-[16px]"
            id="email"
            name="email"
            readOnly={loading}
            value={formData.email}
            onChange={handleChange}
          />
          }
          <Dropdown
            id="role"
            name="role"
            label="Role"
            variant="mb-[16px]"
            options={rolesOptions.filter(item => item.name !== 'Super-Admin')}
            optionType='search'
            loading={isFetchingRoles}
            selected={formData?.role}
            onSelect={(data) => {
              setFormData({ ...formData, role: data.value });
            }}
          />
          {action === 'Edit' && (
            <Dropdown
              id="status"
              name="status"
              variant="mb-[16px]"
              optionType="status"
              label="Status"
              options={statuses}
              selected={formData?.status}
              onSelect={(data) => {
                setFormData({ ...formData, status: data.value });
              }}
            />
          )}
          <Phone value={phone} onChange={setPhone} label="Phone number" showError={!!phone.phoneNumber} />
        </div>
        <div className="modal-right-button-container">
          <Button theme="primary" type="button" name={action === 'Edit' ? "Save Changes" : "Send Invite"} className="w-full" loading={loading} onClick={onSubmit} disabled={disabled || loading} />
        </div>
      </form>
    </Modal>
  );
};

export default InviteUser;

InviteUser.propTypes = {
  closeModal: PropTypes.func,
  action: PropTypes.string,
  isFetchingRoles: PropTypes.bool
};
