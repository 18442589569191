import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { notify } from "store/modules/global"

export default function useDebounce(value, delay = 1000) {
    const [debouncedValue, setDebouncedValue] = useState()
    const [isTyping, setIsTyping] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!value) return setDebouncedValue()
        setIsTyping(true)
        const handler = setTimeout(() => {
            if (value?.includes("<") || value?.includes("/>")) {
                return dispatch(notify({ display: true, status: "error", message: 'Invalid search word or sentence.' }))
            }
            setDebouncedValue(value)
            setIsTyping(false)
        }, delay)

        return () => clearTimeout(handler)
    }, [value])

    return { debouncedValue, isTyping }
}