import React from "react"
import Navbar from "../../components/Navbar/Navbar"
import Button from "components/Button/Button"
import StatCard from "components/StatCard/StatCard"
import { ReactComponent as User } from "assets/icons/user-search.svg"
import { ReactComponent as User1 } from "assets/icons/profile.svg"
import Table from "components/Table/Table"
import Actions from "components/Table/Actions/Actions"
import { user_management_headers } from "constants/table"
import { formatDate } from "utils/formatDate"
import Toolbar from "components/Table/Toolbar/Toolbar"
import useUserManagement from "./useUserManagement"
import Avatar from "components/Avatar/Avatar"
import Status from "components/Status/Status"
import Filter from "./components/Filter/Filter"
import ActivateDeactivateUser from "./components/ActivateDeactivateUser/ActivateDeactivateUser"
import { modules } from "constants/module";
import InviteUser from "./components/InviteUser/InviteUser"
import Restricted from "HOC/Restricted"
import { generateAvatarColor } from "utils/generateAvatarColor"

export default function UserManagement() {
    const { userManagement } = modules
    const {
        actions, selectedAction, resetSelectedAction, openFilterModal, loadingRoles,
        setSelectedAction, setFilters, dateSelection, filters, loading, users, clearFilters,
        handleSearch, changePage, selectLimit, filterModal, selectedFilters, setDateSelection, handleSort,
        handleFilter, closeFilterModal, rolesOptions, userDetails, exportFunc, isExportingData, handleViewUser
    } = useUserManagement()

    return (
        <div>
            <Navbar title="User management" />
            <div className="app-body">
                <div className="flex justify-between mb-[24px]">
                    <p className="text-16 text-neutral_500 font-medium mr-[24px]">Overview</p>
                    <Restricted module={userManagement} permission='Invite user'>
                        <Button
                            name="Invite user"
                            className="text-14 text-brand_white h-[36px]"
                            theme="primary"
                            onClick={() => setSelectedAction('Invite')}
                        />
                    </Restricted>
                </div>
                <Restricted module={userManagement} permission="View users">
                    <div className="grid grid-cols-4 gap-[8px] mb-[16px]">
                        <StatCard
                            icon={<User1 fill="#4F4F51" className="mb-[4px]" />}
                            title="Total users"
                            count={users?.allUsers}
                        />
                        <StatCard
                            icon={<User fill="#55B948" className="mb-[4px]" />}
                            title="Active users"
                            count={users?.activeCount}
                        />
                        <StatCard
                            icon={<User fill="#4F4F51" className="mb-[4px]" />}
                            title="Inactive users"
                            count={users?.inactiveCount}
                        />
                        <StatCard
                            icon={<User fill="#EA242C" className="mb-[4px]" />}
                            title="Deactivated users"
                            count={users?.deactivatedCount}
                        />
                    </div>
                </Restricted>
                <div>
                    <Toolbar
                        title="User management list"
                        data={users?.users}
                        searchPlaceholder="Search by name or email address"
                        filterFnc={openFilterModal}
                        exportFunc={exportFunc}
                        isExportingData={isExportingData}
                        handleSearch={handleSearch}
                        exportPermission="Export users"
                        filters={selectedFilters}
                        module={userManagement}
                        clearFilters={clearFilters}
                    />
                    <Table
                        headers={user_management_headers}
                        data={users?.users}
                        totalCount={users?.count}
                        perPage={users?.limit}
                        currentPage={users?.page}
                        handleSort={handleSort}
                        selectLimit={selectLimit}
                        emptyStateCaption='You have no user information to display yet. Click the “Invite user” button above to add new users.'
                        minHeight="min-h-[48vh]"
                        changePage={changePage}
                        loading={loading}
                    >
                        <>
                            {users?.users?.map((item, i) => (
                                <tr
                                    data-testid={item?.user_id}
                                    key={item?.user_id}
                                    className="cursor-pointer custom-table-data-row"
                                    onClick={() => handleViewUser(item)}
                                >
                                    <td className="flex items-center capitalize">
                                        <div className="flex items-center">
                                            <Avatar
                                                url={item.url}
                                                size={32}
                                                bgColor={generateAvatarColor(i)}
                                                //alt={initials}
                                                initials={`${item?.first_name?.split('')[0] || ''}${item?.last_name?.split('')[0] || ''}`}
                                                className="w-[32px] h-[32px]"
                                            />
                                            <p className='max-w-[280px] truncate ml-[16px]'>{item?.first_name} {item.last_name}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <p className='w-max'>{item?.email}</p>
                                    </td>
                                    <td>
                                        <p className='w-max'>{item?.role ?? 'N/A'}</p>
                                    </td>
                                    <td className="w-[150px]">{formatDate(item?.created_at)}</td>
                                    <td>
                                        <Status status={item.status} />
                                    </td>
                                    <td className="w-[20px]">
                                        <Actions
                                            id={item?.id}
                                            item={item}
                                            actions={actions?.filter(o => o.permission)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </>
                    </Table>
                </div>
            </div>
            {filterModal &&
                <Filter closeModal={closeFilterModal} setFilters={setFilters} dateSelection={dateSelection} setDateSelection={setDateSelection} filters={filters} filterFnc={handleFilter} loading={loading} rolesOptions={rolesOptions} />}
            {["Deactivate", "Activate"].includes(selectedAction) &&
                <ActivateDeactivateUser
                    userDetails={userDetails}
                    closeModal={resetSelectedAction}
                    action={selectedAction}
                />
            }
            {["Edit", "Invite"].includes(selectedAction) &&
                <InviteUser
                    closeModal={resetSelectedAction}
                    action={selectedAction}
                    userDetails={userDetails}
                    isFetchingRoles={loadingRoles}
                />
            }
        </div>
    )
}