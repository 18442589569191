import { useState } from 'react';
import { getCountries } from 'store/modules/global/actions';
import useTryCatch from 'hooks/useTryCatch/useTryCatch';
import useMakeRequest from 'hooks/useMakeRequest/useMakeRequest';
import { countries } from 'services/endpoints/countries';

export default function useCountry() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest();
    const { tryCatch } = useTryCatch();
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([])

    const get_countries = async () => {
        return tryCatch(async () => {
            setLoading(true);
            return makeRequest({ action: getCountries(), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    };

    const get_states = async (payload) => {
        const url = `${countries}/states`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({ url, method: "post", payload, alert: false })
                .then((res) => setStates(res?.data?.states || []))
                .finally(() => setLoading(false));
        });
    };

    return {
        get_countries,
        get_states,
        states,
        loading
    };
}
