import React from "react";
import PropTypes from 'prop-types';

export default function Avatar({ alt, size, url, initials, className, bgColor }) {
    return (
        <div
            className={`
            ${bgColor || 'bg-brand_black'} rounded-full flex ${className} w-[${size}px] h-[${size}px] 
            justify-center items-center text-neutral_white shrink-0 text-16
            `}
        >
            {url ?
                <img
                    src={url}
                    width={size}
                    height={size}
                    alt={alt}
                    data-testid="avatar"
                    className={`rounded-full w-[${size}px] h-[${size}px] bg-brand_primary object-cover object-top`}
                //onClick={() => url && setShowProfile(true)}
                />
                : <p className={`text-brand_white font-bold uppercase leading-none cursor-default`}>{initials}</p>
            }
        </div>
    )
}

Avatar.propTypes = {
    alt: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string,
    initials: PropTypes.string,
    className: PropTypes.string,
    bgColor: PropTypes.string
};
