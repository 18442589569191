import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { injectStore } from 'services/token.service';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'store';
import { Provider } from 'react-redux'

injectStore(store)

const rootElement = document.getElementById('root')

createRoot(rootElement).render(
    // <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    // </React.StrictMode>
);

serviceWorker.unregister();


