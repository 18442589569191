import { useEffect, useState } from "react"

export default function useResendOtp() {
    const [timeLeft, setTimeLeft] = useState(60)

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60).toString().padStart(2, '0')
        const seconds = (time % 60).toString().padStart(2, '0')
        return `${minutes}:${seconds}`
    }

    const resetTimer = () => {
        setTimeLeft(60)
    }

    useEffect(() => {
        let timer = setInterval(() => {
            if (timeLeft !== 0) setTimeLeft(prevState => prevState - 1)
        }, 1000)

        return () => clearInterval(timer)
    },[timeLeft])

    const countDown = formatTime(timeLeft)

    return { timeLeft , countDown, resetTimer }
}