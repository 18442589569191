import React from "react"
import Navbar from "../../components/Navbar/Navbar"

export default function AuditTrail() {
    return (
        <div>
            <Navbar title="Audit trail" />
            <div className="app-body">

            </div>
        </div>
    )
}