import Button from 'components/Button/Button';
import Input from 'components/Inputs/Input/Input';
import React, { useState } from 'react';
import { ReactComponent as SuccessCheck } from 'assets/svg/success-img.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/fetch/useAuth';
import { useAuthState } from 'store/modules/auth';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import { checkEmptyProperties } from 'utils/checkEmptyProperties';

const ResetPasswordForm = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { create_password, loading } = useAuth()
  const { loggedUser } = useAuthState()
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    email: email || loggedUser.email
  });

  const [view, setView] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const enhancedFormData = {...formData}
    removeSpecificKeys(enhancedFormData, ['confirmPassword']);
    create_password(enhancedFormData, () => setView('success'))
  };

  const disableBtn = () => {
    return checkEmptyProperties(formData)  || (formData?.password !== formData.confirmPassword)
  }

  return (
    <section className="bg-brand_white rounded-xl px-10 pt-20 pb-28">
      {!view ? (
        <div>
          <p className="text-[34px] font-semibold leading-[44px] text-neutral_black mb-2">{`${pathname.includes('reset') ? 'Reset' : 'Create'} Password`}</p>
          <p className="text-neutral_700 font-normal leading-6">Kindly enter your log in credentials to continue</p>
          <form className="mt-8" onSubmit={submitForm}>
            <div className="mb-4">
              <Input
                label="New Password"
                type="password"
                id="password"
                name="password"
                readOnly={loading}
                value={formData?.password}
                onChange={handleChange}
              />
            </div>
            <div className="mb-8">
              <Input
                label={pathname.includes('reset') ? 'Confirm new password' : 'Confirm password'}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                readOnly={loading}
                value={formData?.confirmPassword}
                onChange={handleChange}
              />
            </div>
            <Button theme="primary" name="Save password" className="!rounded-[3px] w-full" disabled={disableBtn()} loading={loading} />
          </form>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center mt-4">
          <SuccessCheck />
          <div className="text-center mt-6">
            <p className="text-primary_green_600 text-24 font-semibold">{`${pathname.includes('create') ? 'Password created' : 'Reset successful'}`}</p>
            <p className="text-neutral_700 mt-3">{`You have successfully ${pathname.includes('create') ? 'created' : 'reset'} your password and can now login`}</p>
            <Button theme="primary" name="Log in" className="!rounded-[3px] w-full mt-8" onClick={() => navigate('/')} />
          </div>
        </div>
      )}
    </section>
  );
};

export default ResetPasswordForm;
