import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notify } from '../../store/modules/global/index';
import { AnimatePresence, motion } from 'framer-motion';

export default function Notification() {
  let dispatch = useDispatch();
  const { display, message, status } = useSelector((state) => state.global.notification);
  const style = {
    animate: { y: 0, transition: { type: 'spring', duration: 1 } },
    initial: { y: -200 },
    exit: { y: -200, transition: { type: 'spring', duration: 1 } },
  };

  const close = () => {
    dispatch(notify({ display: false }));
  };

  useEffect(() => {
      let timer = setTimeout(() => {
          close()
      }, 5000)

      return () => clearTimeout(timer)
  }, [display, message])

  return (
    <AnimatePresence>
      {display ? (
        <motion.div
          animate={style.animate}
          initial={style.initial}
          exit={style?.exit}
          data-testid="notification"
          id="notification"
          className={`fixed top-[1rem] max-w-[91.5%] sm:max-w-[560px]
                            rounded-[8px] flex items-center px-4 py-[13px] right-[1rem] z-[1000]
                            ${status === 'success' ? 'bg-primary_green_50' : 'bg-primary_red_500'} ${
            display ? 'flex' : 'hidden'
          }`}
        >
          <div className="">
            {status === 'success' ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <p
            className={`text-14 leading-[22px] ml-[16px] mr-[2rem] text-brand_white font-medium`}
          >
            {message}
          </p>
          <div data-testid="close" className="cursor-pointer hide_tap p-[5px] border-l-[0.5px] border-l-brand_white pl-[16px]" onClick={() => close()}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.33341 11.8334L0.166748 10.6667L4.83342 6.00002L0.166748 1.33335L1.33341 0.166687L6.00008 4.83335L10.6667 0.166687L11.8334 1.33335L7.16675 6.00002L11.8334 10.6667L10.6667 11.8334L6.00008 7.16669L1.33341 11.8334Z"
                fill="white"
              />
            </svg>
          </div>
        </motion.div>
      ) : (
        ''
      )}
    </AnimatePresence>
  );
}
