import React, { useRef, useState } from 'react';
import usePIN from 'hooks/usePIN/usePIN';
import Pin from 'components/Inputs/Pin/Pin';
import { ReactComponent as SuccessCheck } from 'assets/svg/success-img.svg';
import Button from 'components/Button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/fetch/useAuth';
import { extractPIN } from 'utils/extractPin';
import { useAuthState } from 'store/modules/auth';
import useResendOtp from 'hooks/useResendOtp/useResendOtp';

const VerifyOtp = () => {
  const [view, setView] = useState('');
  const inputRef = useRef([]);
  const { otp, onChange, onPress, onPaste } = usePIN(6, inputRef);
  const { verify_otp, loading, validate_reset_code, resend_otp, forgot_password } = useAuth()
  const navigate = useNavigate()
  const userOtp = extractPIN(otp);
  const [searchParams] = useSearchParams();
  const { loggedUser } = useAuthState()
  const email = searchParams.get('email');
  const {timeLeft, resetTimer, countDown} = useResendOtp()
  const handleSubmit = (e) => {
    e.preventDefault();
    email ? validate_reset_code({ userOtp }) : verify_otp({ userOtp }, () => setView('success'))
  };
  const resentOtp = () => {
    if(timeLeft === 0) {
      email ? forgot_password({ email }, resetTimer) : resend_otp(loggedUser?.email ?? email, resetTimer)
    } 
  };

  return (
    <section className="bg-brand_white rounded-xl px-10 pt-20 pb-[140px]">
      {!view ? (
        <div>
          <p className="text-[34px] font-semibold leading-[44px] text-neutral_black mb-2">Verify email</p>
          <p className="text-neutral_700 font-normal leading-6">
            Enter OTP sent to <span className="font-semibold">{loggedUser?.email || email}</span>
          </p>
          <form className="mt-8" onSubmit={handleSubmit} data-testid="form">
            <Pin
              onChange={onChange}
              onPaste={onPaste}
              onKeyDown={onPress}
              readOnly={loading}
              type='text'
              id="otp_input"
              pin={otp}
              inputRef={inputRef}
              className="flex w-full max-w-md mx-auto"
            />
            <Button
              theme="primary"
              loading={loading}
              disabled={userOtp?.length < 6}
              name="Verify"
              className="!rounded-[3px] w-full mt-10"
            />
          </form>
          <div className="mt-4 text-center">
            <p className="text-neutral_500 text-sm leading-5">Didn’t receive code?</p>
            <p className={`mt-2 text-primary_red_500 font-semibold inline-flex ${ timeLeft === 0 ? 'underline cursor-pointer' : 'cursor-default'}`} onClick={resentOtp}>Resend {timeLeft === 0 ? '' : `in ${countDown}` }</p>
          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center mt-4'>
          <SuccessCheck />
          <div className='text-center mt-6'>
            <p className='text-primary_green_600 text-24'>Email verified</p>
            <p className='text-neutral_700 mt-3'>Your email has been verified, you’re now logged in</p>
          </div>
          <Button theme="primary" name="Go to Dashboard" className="!rounded-[3px] w-full mt-8" onClick={() => navigate('/user-management')} />
        </div>
      )}
    </section>
  );
};

export default VerifyOtp;
