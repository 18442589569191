import React, { useEffect } from "react";
import PropTypes from "prop-types"
import Sidebar from "../Sidebar/Sidebar";
import RequireAuth from "HOC/RequireAuth";
import useCountry from "hooks/fetch/useCountry";

export default function AppLayout({ children }) {
    const { get_countries } = useCountry()

    useEffect(() => {
        get_countries()
    }, [])

    return (
        <RequireAuth>
            <div className="flex w-full">
                <Sidebar />
                <div className="w-full bg-neutral_white rounded-[16px] overflow-x-hidden">
                    {children}
                </div>
            </div>
        </RequireAuth>
    )
}

AppLayout.propTypes = {
    children: PropTypes.element
}