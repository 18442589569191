import React, { useState } from 'react'
import Input from 'components/Inputs/Input/Input'
import Button from 'components/Button/Button'
import useAuth from 'hooks/fetch/useAuth';

const ForgotPasswordForm = () => {
    const { forgot_password, loading } = useAuth()

    const [formData, setFormData] = useState({
        email: '',
    })
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const submitForm = (e) => {
        e.preventDefault();
        forgot_password(formData)
      };
    return (
        <section className='bg-brand_white rounded-xl px-10 pt-20 md:pb-48'>
            <p className='lg:text-[34px] text-24 font-semibold leading-[44px] text-neutral_black mb-2'>Forgot password</p>
            <p className='text-neutral_700 font-normal leading-6'>Enter your registered email to receive OTP</p>
            <form className='mt-8' onSubmit={submitForm}>
                <div className="mb-4">
                    <Input
                        label="Email"
                        type="text"
                        id="email"
                        name="email"
                        readOnly={loading}
                        value={formData?.email}
                        onChange={handleChange}
                    />
                </div>
                <Button
                    theme="primary"
                    loading={loading}
                    disabled={!formData.email}
                    name="Verify"
                    className="!rounded-[3px] w-full"
                />
            </form>
        </section>
    )
}

export default ForgotPasswordForm