import React from "react";
import PropTypes from 'prop-types';
import { ReactComponent as CaretLeft } from "assets/icons/caret-left.svg";
import { ReactComponent as CaretRight } from "assets/icons/caret-right.svg";
import { ReactComponent as CaretDown } from "assets/icons/caret-down.svg";
import useOutsideTouch from "hooks/useOutsideTouch/useOutsideTouch";

export default function Pagination({ changeLimit, limit, dataLength, totalCount, currentPage, changePage }) {
    const { ref, display, setDisplay } = useOutsideTouch()
    const buttonStyle = "w-[32px] h-[32px] flex justify-center items-center rounded-[4px]"
    const limits = [10, 20, 50]
    const previousPage = currentPage - 1
    const nextPage = currentPage + 1
    const disableNextPage = (currentPage * limit) > totalCount
    const disablePreviousPage = currentPage === 1

    return (
        <>
            {dataLength ?
                <div className="flex mb-[16px] pt-[16px] items-center text-14 relative">
                    <div className="flex">
                        <button
                            data-testid="previous"
                            className={`${buttonStyle} ${disablePreviousPage ? "bg-[#E8E8E8]" : "bg-[#C2C2C5]"} mr-[8px]`}
                            onClick={() => changePage(previousPage)}
                            disabled={disablePreviousPage}
                        >
                            <CaretLeft fill={disablePreviousPage ? "#919195" : "#424248"} />
                        </button>
                        <button
                            data-testid="next"
                            className={`${buttonStyle} ${disableNextPage ? "bg-[#E8E8E8]" : "bg-[#C2C2C5]"} bg-[#C2C2C5]`}
                            onClick={() => changePage(nextPage)}
                            disabled={disableNextPage}
                        >
                            <CaretRight fill={disableNextPage ? "#919195" : "#424248"} />
                        </button>
                    </div>
                    <p className='text-neutral_400 font-normal mx-[16px] cursor-default'>
                        {disablePreviousPage ? 1 : (limit * (previousPage))}-
                        {disablePreviousPage ? dataLength : (disableNextPage ? totalCount : (limit * currentPage))} of {totalCount}
                    </p>
                    <div ref={ref} className="w-[128px] relative">
                        <button
                            data-testid={`${limit} rows`}
                            className="relative flex items-center border border-neutral_300 rounded-[8px] h-[32px] w-[120px] px-[8px] cursor-pointer"
                            onClick={() => setDisplay(!display)}
                        >
                            <p className="text-14 text-neutral_400">{limit} rows</p>
                            <CaretDown className="absolute right-[12px] cursor-pointer" />
                        </button>
                        {display &&
                            <div
                                className="absolute bottom-[10px] py-[8px] px-[8px] overflow-auto bg-brand_white drop-shadow-md w-[120px] lg:drop-shadow-md rounded-[8px]"
                            >
                                {limits?.map((limit) =>
                                    <button
                                        key={limit}
                                        data-testid={limit}
                                        onClick={() => {
                                            setDisplay(false)
                                            changeLimit(limit)
                                        }}
                                        className={`w-full py-[8px] px-[10px] flex items-center justify-between cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3] rounded-[8px]`}
                                    >
                                        {limit} rows
                                    </button>
                                )}
                            </div>
                        }
                    </div>
                </div> : <></>
            }
        </>
    )
}

Pagination.propTypes = {
    changeLimit: PropTypes.func,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
    changePage: PropTypes.func,
    dataLength: PropTypes.number,
    limit: PropTypes.number
};