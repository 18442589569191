import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useInputValidate from 'hooks/useInputValidate/useInputValidate';
import { ReactComponent as EyeOpen } from 'assets/icons/eye-open.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-close.svg';
import "./Input.css"

export default function Input({
    id,
    name,
    placeholder,
    value,
    label,
    onChange,
    type,
    readOnly,
    symbol,
    className,
    helperText,
    onBlur,
    maxLength,
    defaultValue,
    max,
    pattern,
    inputMode,
    showError = true,
    disabled,
    variant,
}) {
    const { error, validate, setError } = useInputValidate(showError);
    const [passwordView, setPasswordView] = useState(false);

    const inputError = useMemo(() => {
        return !(showError === false || !error)
    }, [error]);

    const onBlurAction = () => {
        validate({ name, value })
        if (value && onBlur) {
            onBlur()
        }
    }

    return (
        <div className={`relative input-container ${className}`}>
            {label &&
                <label
                    htmlFor={id}
                    className={`
                        ${disabled ? 'bg-none' : 'bg-brand_white'} block
                        text-14 text-neutral_500 mb-[4px] cursor-default ${variant}`}
                >
                    {label}
                </label>
            }
            <input
                id={id}
                name={name}
                type={(type === 'password' && (passwordView ? 'text' : 'password')) || type}
                placeholder={placeholder || ''}
                value={value}
                disabled={disabled}
                defaultValue={defaultValue}
                maxLength={maxLength}
                inputMode={inputMode}
                max={max}
                pattern={pattern}
                data-testid={id}
                aria-labelledby={id}
                onChange={onChange}
                readOnly={readOnly}
                autoComplete="off"
                onBlur={onBlurAction}
                onKeyDown={() => setError('')}
                className={`${variant} 
                        h-[48px] ${symbol ? 'pl-7 pr-4' : 'px-4'} text-neutral_600 text-14 w-full outline-0 border hide_tap
                        rounded-[4px] focus:border-blue_7
                        ${disabled ? 'bg-neutral_100 border-neutral_100' : 'bg-brand_white border-stroke_line'} 
                    `}
            />
            {type === 'password' && (
                <div
                    onClick={() => setPasswordView(!passwordView)}
                    data-testid={!passwordView ? 'show' : 'hide'}
                    className="flex items-center absolute top-3 right-[13.48px] cursor-pointer hide_tap h-full"
                >
                    {!passwordView ? <EyeClosed /> : <EyeOpen />}
                </div>
            )}
            {symbol && (
                <div className="flex items-center absolute left-4 h-full top-3">
                    <div className="text-14 text-neutral_500">{symbol}</div>
                </div>
            )}
            {showError && <p className={`${inputError ? 'text-primary_red_500' : 'text-neutral_black'} text-12`}>
                {(inputError && error) || helperText}
            </p>
            }
        </div>
    );
}

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.string,
    symbol: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    maxLength: PropTypes.number,
    max: PropTypes.string,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    showError: PropTypes.bool,
    helperText: PropTypes.string,
    className: PropTypes.string
};
