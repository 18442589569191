import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import { forgotPassword, login, refreshToken, verifyOtp } from './actions';

const initialState = {
  loggedUser: {},
  permissions: [],
  role: '',
  isLoggedIn: false,
  error: null,
  refreshToken: '',
  authTokenExpiry: '',
  authToken: '',
};

const setLoggedUser = (state, action) => {
  const { token, expires, permissions, role, refreshToken } = action.payload.data
  state.loggedUser = { ...state.loggedUser, authToken: token, authTokenExpiry: expires }
  state.permissions = permissions
  state.role = role
  state.isLoggedIn = true;
  state.authToken = token;
  state.authTokenExpiry = expires;
  state.refreshToken = refreshToken
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.code === 200) state.loggedUser = action?.payload?.data
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        if (action.payload.code === 200) setLoggedUser(state, action)
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loggedUser = { ...state.loggedUser, ...action?.payload?.data }
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          const { token, expires, refreshToken } = action.payload.data
          state.authToken = token;
          state.expires = expires;
          state.refreshToken = refreshToken
        }
      })
  },
});
// Selectors
const selectAuth = (state) => state.auth;

//App Redux State
export const useAuthState = () => useAppSelector(selectAuth);

export default auth.reducer;
