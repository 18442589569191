import React from "react"
import PropTypes from "prop-types"
import Navbar from "components/Navbar/Navbar"
import Avatar from "components/Avatar/Avatar"
import { formatDate } from "utils/formatDate"
import Button from "components/Button/Button"
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg"
import { useNavigate } from "react-router-dom"
import Status from "components/Status/Status"
import { useUserState } from "store/modules/users"
import { userInitals } from "utils/userInitials"
import DeactivateUser from "../components/ActivateDeactivateUser/ActivateDeactivateUser"
import InviteUser from "../components/InviteUser/InviteUser"
import useUserManagement from "../useUserManagement"
import Restricted from "HOC/Restricted"
import { modules } from "constants/module";

export default function ViewSingleUser() {
    const navigate = useNavigate()
    const {selectedAction, resetSelectedAction, setSelectedAction } = useUserManagement()
    const {selectedUser} = useUserState()
    const {userManagement} = modules
    
    return (
        <div>
            <Navbar title="User management" />
            <div className="app-body">
                <div className="flex justify-between items-center mb-[16px]">
                    <Button
                        name="Back"
                        icon={<ArrowLeft />}
                        className="h-[36px] border-0"
                        onClick={() => navigate(-1)}
                    />
                    <div className="flex">
                        <Restricted module={userManagement} permission='Edit user'>
                            <Button
                                name="Edit user"
                                className="h-[36px] mr-[12px]"
                                theme="transparent"
                                onClick={() => setSelectedAction('Edit')}
                            />
                        </Restricted>
                        { ['active', 'deactivated'].includes(selectedUser?.status) && 
                        <Restricted module={userManagement} permission={selectedUser?.status === 'active' ? 'Deactivate user' : 'Activate user'}>
                            <Button
                            name={`${ selectedUser?.status === 'active' ? 'Deactivate' : 'Activate'} user`}
                            className="h-[36px]"
                            theme="primary"
                            onClick={() => setSelectedAction('deactivate')}
                            />
                        </Restricted>
                        }
                    </div>
                </div>
                <div className="border border-neutral_100 rounded-[8px] p-[24px]">
                    <div className="flex items-center mb-[40px]">
                        <Avatar
                            url=""
                            alt={selectedUser?.first_name}
                            initials={userInitals(selectedUser?.first_name, selectedUser?.last_name)}
                            className="mr-[16px] w-[88px] h-[88px]"
                        />
                        <Status status={selectedUser?.status} />
                    </div>
                    <div className="grid grid-cols-3 gap-[24px]">
                        <Info title="First name" value={selectedUser?.first_name} />
                        <Info title="Last name" value={selectedUser?.last_name} />
                        <Info title="Phone number" value={`${selectedUser?.country_code ?? ''} ${selectedUser?.phone_number || 'N/A'}`} />
                        <Info title="Email address" value={selectedUser?.email} />
                        <Info title="Role" value={selectedUser?.role} />
                        <Info title="Date created" value={formatDate(selectedUser?.created_at)} />
                    </div>
                </div>
            </div>
            {selectedAction === 'deactivate' && 
                <DeactivateUser 
                userDetails={selectedUser} 
                closeModal={resetSelectedAction} 
                action={selectedUser?.status === 'active' ? 'Deactivate' : 'Activate'}
                />
            }
            {selectedAction === 'Edit' && <InviteUser closeModal={resetSelectedAction} action={selectedAction}/>}
        </div>
    )
}

const Info = ({ title, value, className }) => {
    return (
        <div className={className}>
            <p className="text-14 text-neutral_400 font-normal mb-[4px]">{title}</p>
            <p className="text-16 text-neutral_700 font-normal">{value}</p>
        </div>
    )
}

Info.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string
}