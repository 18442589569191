import React, { useState } from "react"
import PropTypes from 'prop-types'
import { ReactComponent as Sort } from "assets/icons/sort.svg"
import CustomSkeleton from "components/Skeleton/Skeleton"
import EmptyState from "./EmptyState/EmptyState"
import Pagination from "../Pagination/Pagination"
import './Table.css'

export default function Table({
    headers,
    data,
    children,
    loading,
    minHeight,
    tableContainer,
    totalCount,
    currentPage,
    changePage,
    hasHeader = true,
    hasPagination = true,
    selectLimit,
    handleSort,
    // module,
    // permission,
    emptyStateTitle,
    emptyStateCaption
}) {
    const [limit, setLimit] = useState(10)

    const changeCurrentPage = (e) => {
        if (changePage) changePage(e);
    };

    const changeLimit = (e) => {
        setLimit(e);
        selectLimit(e);
    };

    return (
        <div className='h-full mt-4' data-testid="table">
            <div className={`w-full overflow-x-auto ${minHeight || "min-h-[500px]"}`}>
                <table className={`w-full ${tableContainer || 'min-w-[700px]'}`}>
                    {hasHeader && data?.length ?
                        <thead>
                            <tr className="border-b border-b-neutral_100 py-[12px]">
                                {headers?.map((header, i) =>
                                    <th
                                        key={i}
                                        className="
                                        text-12 text-left font-medium text-neutral_500 uppercase
                                        px-[10px] pb-[12px] whitespace-nowrap cursor-default"
                                    >
                                        {header.name}
                                        {header.sort && <Sort data-testid='sort' className="cursor-pointer inline ml-[8px]" onClick={handleSort} />}
                                    </th>
                                )}
                            </tr>
                        </thead> : <></>
                    }
                    <tbody>
                        {data?.length && !loading ? children : <></>}
                    </tbody>
                </table>
                {!loading && !data?.length ? <EmptyState title={emptyStateTitle} caption={emptyStateCaption} className="mt-[188px]" /> : <></>}
                {loading &&
                    <div className="py-[20px]">
                        <CustomSkeleton count={4} className="mb-[8px] py-[4px]" />
                    </div>
                }
            </div>
            {data?.length && hasPagination ?
                <Pagination
                    totalCount={Number(totalCount)}
                    dataLength={data?.length}
                    currentPage={Number(currentPage) || 0}
                    changePage={changeCurrentPage}
                    changeLimit={changeLimit}
                    limit={limit}
                /> : ''
            }
        </div>
    )
}

Table.propTypes = {
    headers: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    data: PropTypes.array,
    children: PropTypes.element,
    loading: PropTypes.bool,
    totalCount: PropTypes.any,
    currentPage: PropTypes.any,
    changePage: PropTypes.func,
    handleSort: PropTypes.func,
    hasPagination: PropTypes.bool,
    hasHeader: PropTypes.bool,
    tableContainer: PropTypes.string,
    module: PropTypes.string,
    permission: PropTypes.string,
    emptyStateCaption: PropTypes.string,
    emptyStateTitle: PropTypes.string,
    minHeight: PropTypes.string,
    selectLimit: PropTypes.func
}
