import { useState } from 'react';
import useMakeRequest from 'hooks/useMakeRequest/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch/useTryCatch';
import { getRoles } from 'store/modules/roles/actions';

export default function useRoles() {
    const { makeRequest, makeAnonymousRequest } = useMakeRequest();
    const { tryCatch } = useTryCatch();
    const [loading, setLoading] = useState(false);

    const get_roles = (payload, callback) => {
        tryCatch(async () => {
            setLoading(true);
            makeRequest({ action: getRoles(payload), alert: false, callback })
                .finally(() => setLoading(false));
        });
    };

    const get_role = (roleName) => {
        const url = `/roles/details/${roleName}`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({ url, method: "get", alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    };

    const get_assigned_role_users = (roleName) => {
        const url = `/users/role?role=${roleName}`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({ url, method: "get", alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    };

    const delete_role = (roleName, callback) => {
        const url = `/roles/${roleName}`
        tryCatch(async () => {
            setLoading(true);
            makeAnonymousRequest({ url, method: "delete", callback })
                .then(res => res?.code === 200 && get_roles({ pathname: "/all" }))
                .finally(() => setLoading(false));
        });
    };

    const update_role = (payload, callback) => {
        const url = `/roles/update`
        tryCatch(async () => {
            setLoading(true);
            makeAnonymousRequest({ url, method: "put", payload, callback })
                .then(res => res?.code === 200 && get_roles({ pathname: "/all" }))
                .finally(() => setLoading(false));
        });
    };

    const create_role = (payload, callback) => {
        const url = `/roles`
        tryCatch(async () => {
            setLoading(true);
            makeAnonymousRequest({ url, method: "post", payload, callback })
                .then(res => res?.code === 200 && get_roles({ pathname: "/all" }))
                .finally(() => setLoading(false));
        });
    };

    const get_roles_no_limit = () => {
        const url = `/roles/all?noLimit=true`
        return tryCatch(async () => {
            setLoading(true);
            return makeAnonymousRequest({ url, method: "get", alert: false })
                .then((res) => res)
                .finally(() => setLoading(false));
        });
    }

    return {
        loading,
        get_roles,
        get_role,
        get_assigned_role_users,
        get_roles_no_limit,
        delete_role,
        update_role,
        create_role
    };
}
