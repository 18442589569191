import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { Bar, Line, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
    BarElement
} from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register required Chart.js elements
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, BarElement, ChartDataLabels, Title, Tooltip, Legend, Filler);

// Default chart options
const defaultOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: { duration: 1000 }, 
};

const ChartWrapper = ({ type, data, options, className }) => {
    const chartRef = useRef(null);
    const chartOptions = { ...defaultOptions, ...options }

    return (
        <div className={`relative w-full ${className}`}>
            {type === "line" && <Line ref={chartRef} data={data} options={chartOptions} />}
            {type === "pie" && <Pie ref={chartRef} data={data} options={chartOptions} />}
            {type === "bar" && <Bar ref={chartRef} data={data} options={chartOptions} />}
        </div>
    );
};

export default ChartWrapper;

ChartWrapper.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.object,
    options: PropTypes.object,
};