export const monthlyChartLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const defaultOptions = {
    plugins: {
        legend: {
            display: false,
            align: "start",
            position: "bottom"
        },
        datalabels: {
            formatter: function () {
                return ``;
            }
        }
    },
    scales: {
        y: {
            beginAtZero: true,
        },
    }
}

export const pieChartDataLabelsOptions = {
    color: "white",
    font: {
        weight: 'bold'
    },
    formatter: function (value) {
        return `${Math.round(value)}%`;
    }
}