import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'services/axios-instance';
import { auth } from 'services/endpoints';

const customAsyncThunk = (thunkName, method, url) => {
    return createAsyncThunk(thunkName, async (payload) => {
        try {
            const body = { ...payload }
            const params = payload?.params ? payload?.params : ''
            const response = await instance[method || 'get'](`${url}/${params}`, body)
            if (response?.data) return response.data ? response.data : {}
            if (response?.response?.data) return response.response.data ? response.response.data : {}
        } catch (error) {
            return error
        }
    })
}

const login = customAsyncThunk('auth/login', 'post', auth.login);
const verifyOtp = customAsyncThunk('auth/verifyOtp', 'post', auth.verify_otp);
const forgotPassword = customAsyncThunk('auth/forgotPassword', 'post', auth.forgot_password);
const createPassword = customAsyncThunk('auth/createPassword', 'put', auth.create_password);
const refreshToken = customAsyncThunk('auth/refreshToken', 'post', auth.refresh_token);
const validateResetPasswordCode = customAsyncThunk('auth/validateResetPasswordCode', 'post', auth.validate_reset_password_code);
const resendOtp = customAsyncThunk('auth/resendOtp', 'post', auth.resent_otp);
const logout = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'logout' });
});

export {
    login,
    verifyOtp,
    logout,
    forgotPassword,
    createPassword,
    validateResetPasswordCode,
    resendOtp,
    refreshToken
}