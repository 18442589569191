import useDriver from "hooks/fetch/useDrivers"
import { useAppDispatch } from "hooks/useReduxHook"
import useTable from "hooks/useTable/useTable"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { setSelectedDriver, useDriverState } from "store/modules/drivers"
import { formatDate } from "utils/formatDate"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"
import { exportData } from "utils/exportData"
import useTryCatch from "hooks/useTryCatch/useTryCatch"
import { modules } from "constants/module"
import usePermission from "hooks/usePermission/usePermission"

export default function useDrivers() {
    const [selectedAction, setSelectedAction] = useState("");
    const { tryCatch } = useTryCatch();
    const navigate = useNavigate()
    const {customerManagement} = modules
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermission()
    const {id} = useParams();
    const {get_drivers, loading, edit_driver} = useDriver();
    const {get_drivers_no_limit, loading: isExportingData} = useDriver()
    const { changePage, selectLimit, handleSearch, params, openFilterModal, closeFilterModal, filterModal } = useTable();
    const { drivers, selectedDriver } = useDriverState();
    const [filters, setFilters] = useState({
        vehicleName: '',
        status: ''
    });
    
    const [selectedFilters, setSelectedFilters] = useState()

    const viewDriver = (item) => {
        navigate(`driver/${item?.driver_id}?type=payment-and-transactions`)
        dispatch(setSelectedDriver(item));
        scrollTo(0,0)
    }

    const actions = [
        {
            name: "Edit driver",
            permission: hasPermission(customerManagement, "Edit driver’s info"),
            method: (item) => {
                setSelectedAction("Edit")
                dispatch(setSelectedDriver(item));
            }
        },
        {
            name: "View driver",
            permission: hasPermission(customerManagement, "View community"),
            method: (item) => viewDriver(item)
        },
        {
            name: "Deactivate driver",
            permission: hasPermission(customerManagement, "Edit driver’s info"),
            method: (item) => {
                dispatch(setSelectedDriver(item));
                setSelectedAction("Deactivate")
            }
        },
        {
            name: "Activate driver",
            permission: hasPermission(customerManagement, "Edit driver’s info"),
            method: (item) => {
                dispatch(setSelectedDriver(item));
                setSelectedAction("Activate")
            }
        }
    ]

    const addDriverActions = [
        {
            name: "Add driver manually",
            method: () => setSelectedAction("Add")
        },
        {
            name: "Bulk upload drivers(csv)",
            method: () => setSelectedAction("bulkUpload")
        },
    ]

    const exportFunc = useCallback(() => {
        get_drivers_no_limit(id, 'Community')
            .then(res => {
                if (res?.code === 200) {
                    tryCatch(() => {
                        const data = res?.data?.drivers?.map((item) => {
                            return {
                                "Name": `${item?.first_name} ${item?.last_name}`,
                                "Email Address": item?.email,
                                "Phone number": `${item?.country_code}${item?.phone}` ?? 'N/A',
                                "Vehicle name": item?.vehicle_name,
                                "Vehicle number": item?.vehicle_number,
                                "Vehicle model": item?.vehicle_model,
                                "Original Equipment Manufacturer (OEM)" : item?.vehicle_oem,
                                "Status": item?.status,
                                "Date created": formatDate(item?.created_at)
                            };
                        });
                        exportData(data, "Drivers")
                    })
                }
            })
    }, [])

    const getAllDrivers = () => {
        const callback = selectedFilters ? closeFilterModal : null
        const payload = !selectedFilters ? { params: {...params, entityType: 'Community'} } : { params: { ...params, ...removeKeysWithEmptyValues(selectedFilters), entityType: 'Community' } }
        get_drivers(`/${id}`, payload, callback)
    }

    const handleFilter = (e) => {
        e.preventDefault()
        setSelectedFilters({...filters})
    }
    const resetSelectedAction = () => {
        setSelectedAction("")
        dispatch(setSelectedDriver());
    }

    const updateDriverStatus = (e) => {
        e.preventDefault()
        const callback = () => {
            getAllDrivers()
            resetSelectedAction()
        }
        const status = selectedDriver?.status === 'active' ? 'deactivated' : 'active'
        edit_driver(`/${selectedDriver?.driver_id}`, {status}, callback)
    }

    const clearFilters = () => {
        setFilters({
            vehicle_name: '',
            status: ''
        })
        setSelectedFilters()
    }

    useEffect(() => {
        getAllDrivers()
    }, [params, selectedFilters])

    return {
        selectedAction,
        selectedDriver,
        resetSelectedAction,
        setSelectedDriver,
        openFilterModal,
        filterModal,
        isExportingData,
        exportFunc,
        updateDriverStatus,
        clearFilters,
        setFilters,
        loading,
        filters,
        drivers,
        closeFilterModal,
        changePage,
        selectedFilters,
        selectLimit,
        handleFilter,
        getAllDrivers,
        handleSearch,
        addDriverActions,
        actions,
        viewDriver
    }
}