export const formatDate = (date, type = 'string') => {
  if (!date) return null
  if (type === 'string') {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = new Date(date).getDate();
    const month = months[new Date(date).getMonth()];
    const year = new Date(date).getFullYear();
    return `${day} ${month}, ${year}`;
  }
  return new Date(date)?.toISOString()?.split("T")[0]
}