import React, { Suspense } from "react"
import PropTypes from "prop-types"
import Navbar from "components/Navbar/Navbar"
import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg"
import Status from "components/Status/Status"
import { userInitals } from "utils/userInitials"
import useDrivers from "../useDrivers"
import { useNavigate, useSearchParams } from "react-router-dom"
import Spinner from "components/Spinner/Spinner"
import PaymentTransactions from "../../../Analytics/PaymentTransactions/PaymentTransactions"
import Telemetry from "../../../Analytics/Telemetry/Telemetry"
import { formatCurrency } from "utils/formatCurrency"

export default function ViewSingleDriver() {
    const {selectedDriver} = useDrivers()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const type = searchParams.get("type")

    const tabs = [
        {
            title: 'Payment/Transactions',
            component: <PaymentTransactions/>,
            type: 'payment-and-transactions',
        },
        {
            title: 'Telemetry',
            component: <Telemetry/>,
            type: 'telemetry',
        },
        {
            title: 'Sustainability impact',
            component: <p>Sustainability</p>,
            type: 'sustainability-impact',
        }
    ];

    return (
        <div>
            <Navbar title="Communities" />
            <div className="app-body">
                <div className="flex justify-between items-center mb-[16px]">
                    <Button
                        name="Back"
                        type="button"
                        icon={<ArrowLeft />}
                        className="h-[36px] border-0 text-neutral_500"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="app-section">
                    <p className="text-16 text-neutral_600 font-semibold mb-[40px]">Driver&apos;s Info</p>
                    <div className="flex items-center mb-[40px]">
                        <Avatar
                            url=""
                            alt=""
                            initials={userInitals(selectedDriver?.first_name, selectedDriver?.last_name)}
                            className="mr-[16px] w-[88px] h-[88px]"
                        />
                        <Status status={selectedDriver?.status} />
                    </div>
                    <div className="grid grid-cols-4 gap-[24px] mb-[40px]">
                        <Info title="Full name" value={`${selectedDriver?.first_name} ${selectedDriver?.last_name}`} />
                        <Info title="Email address" value={selectedDriver?.email} />
                        <Info title="Phone number" value={`${selectedDriver?.country_code}${selectedDriver?.phone}`} />
                    </div>
                    <div>
                        <p className="text-14 text-primary_green_500 font-semibold mb-[24px]">Vehicle type</p>
                        <div className="grid grid-cols-4 gap-[24px]">
                            <Info title="Name" value={selectedDriver?.vehicle_name} />
                            <Info title="Original Equipment Manufacturer (OEM)" value={selectedDriver?.vehicle_oem} />
                            <Info title="Model" value={selectedDriver?.vehicle_model} />
                            <Info title="Vehicle number" value={selectedDriver?.vehicle_number} />
                            <Info title="Equity contribution for vehicle" value={formatCurrency(selectedDriver?.equity_contribution)} />
                            <Info title="Daily contribution to make" value={formatCurrency(selectedDriver?.daily_contribution)} />
                            <Info title="Outstanding balance" value={formatCurrency(selectedDriver?.outstanding_bal)} />
                        </div>
                    </div>
                </div>
                <div className="mt-12">
                    <div className="border-t border-x border-neutral_100 rounded p-6 grid grid-cols-3">
                        {tabs.map((t) => (
                            <button
                                key={t.title}
                                className={`text-16 font-normal mr-[24px] ${type === t.type ? 'selected-tab' : 'unselected-tab'}`}
                                onClick={() => navigate(`?type=${t.type}`)}
                            >
                                {t.title}
                            </button>
                        ))}
                    </div>
                    <Suspense fallback={<Spinner />}>{tabs.find((t) => t.type === type)?.component}</Suspense>
                </div>
            </div>
        </div>
    )
}

const Info = ({ title, value, className }) => {
    return (
        <div className={className}>
            <p className="text-14 text-neutral_400 font-normal mb-[4px]">{title}</p>
            <p className="text-16 text-neutral_700 font-normal">{value}</p>
        </div>
    )
}

Info.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string
}