import { unwrapResult } from "@reduxjs/toolkit"
import { logout, refreshToken } from "store/modules/auth/actions"
import { notify } from "store/modules/global"

let store
export const injectStore = _store => store = _store
export const getUser = () => store?.getState()?.auth

export const refreshUserToken = () => {
    const refresh_token = getUser()?.refreshToken
    return store.dispatch(refreshToken({ refreshToken: refresh_token }))
        .then(unwrapResult)
        .then(res => {
            if (res?.code !== 200) {
                store.dispatch(notify({ display: true, status: 'error', message: res?.message }))
                store.dispatch(logout())
                return
            }
            return res
        })
        .catch(err => err)
}