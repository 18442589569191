import React, { useState } from 'react';
import { ReactComponent as Money4 } from "assets/icons/money-4.svg"
import StatCard from 'components/StatCard/StatCard';
import PaymentTrend from './components/PaymentTrend';
import PaymentVolumePerPaymentPeriod from './components/PaymentVolume';
import TotalPayment from './components/TotalPayment';
import PaymentChannels from './components/PaymentChannels';
import useIntervalActions from 'hooks/useIntervalActions/useIntervalActions';

const PaymentTransactions = () => {
  const {selectedPeriod, periods} = useIntervalActions()
  const tabs = ['Swap stations', 'Charge stations']
  const [selectedTab, setSelectedTab] = useState('Swap stations')

  return (
    <div>
      <div className="border-x border-neutral_100 p-4">
        <h3 className="text-16 font-semibold mb-[16px]">Stations</h3>
        {tabs.map((t) => (
            <button
                key={t}
                className={`text-16 font-normal mr-[24px] ${t === selectedTab ? 'selected-tab' : 'unselected-tab'}`}
                onClick={() => setSelectedTab(t)}
            >
                {t}
            </button>
        ))}
      </div>
      <div className='grid md:grid-cols-2 gap-4 grid-cols-1 '>
        <StatCard
          title={`Total ${ selectedTab === 'Swap stations' ? 'swap' : 'charge'} payments made`}
          icon={<Money4 />}
          countVariant='text-18 font-semibold'
          count={`NGN 120, 000`}
          hint={true}
          rate='decrease'
        />
        <div className='grid grid-cols-12 gap-2'>
        <StatCard
          countVariant='text-18 font-semibold'
          title="Total payment volume"
          containerVariant='col-span-5'
          icon={<Money4 />}
          count={`230`}
        />
        <StatCard
          title="Total payments value"
          icon={<Money4 />}
          count={`NGN 78, 000`}
          containerVariant='col-span-7'
          id='paymentValue'
          countVariant='text-18 font-semibold'
          actions={periods}
          selectedAction={selectedPeriod}
          hint={true}
          rate='increase'
        />
        </div>
      </div>
      <div className='grid grid-cols-12 mt-6 gap-8'>
        <div className='col-span-8'>
          <PaymentTrend/>
          <PaymentVolumePerPaymentPeriod/>
          <PaymentTrend amount='NGN 120, 000'/>
        </div>
        <div className='col-span-4'>
          <TotalPayment/>
          <PaymentChannels/>
        </div>
      </div>
    </div>
  );
};

export default PaymentTransactions;
