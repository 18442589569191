import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"

export default function SubMenuOptions({ options, title, titleIcon, titleUrl }) {
    const { pathname } = useLocation()
    const isTitleActive = pathname.match(titleUrl) && pathname.match(titleUrl)[0]
    const [show, setShow] = useState(!!isTitleActive)
  
    const isSubMenuActive = (url) => {
        const enhancedUrl = url?.split("?")[0]
        const reg = pathname.match(enhancedUrl) && pathname.match(enhancedUrl)[0]
        return reg
    }

    useEffect(() => {
        if (!isTitleActive) setShow(false)
    }, [isTitleActive])

    return (
        <>
            <button
                type="button"
                className={`
                flex items-center whitespace-nowrap px-[16px] py-[8px] rounded-[8px]
                nav-list w-full ${isTitleActive ? "nav-list-selected" : ""}
                `}
                onClick={() => setShow(!show)}
            >
                {titleIcon}
                <p className="text-14 text-brand_white ml-[12px] font-normal">{title}</p>
            </button>
            {show &&
                <div className="bg-[#1A1A1C] rounded-bl-[8px] rounded-br-[8px] py-[8px]">
                    {options?.filter(o => o.permitted).map((item) =>
                        <Link
                            key={item?.name}
                            data-testid={item?.name}
                            to={item?.url}
                            className={`
                            flex items-center justify-between whitespace-nowrap px-[16px] py-[8px] rounded-[8px]
                            mb-[8px] w-full
                            `}
                        >
                            <div className="flex items-center">
                                {item?.icon}
                                <p className="text-14 text-brand_white ml-[12px] font-normal">{item?.name}</p>
                            </div>
                            {isSubMenuActive(item?.url) && <div className="w-[12px] h-[12px] bg-primary_green_500 rounded-full"></div>}
                        </Link>
                    )}
                </div>
            }
        </>
    )
}

SubMenuOptions.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    titleUrl: PropTypes.string,
    titleIcon: PropTypes.element
}