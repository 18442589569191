export const role_headers = [
    {
        name: 'name of role',
        sort: false,
        action: null
    },
    {
        name: 'description',
        sort: false,
        action: null
    },
    {
        name: 'Users assigned',
        sort: false,
        action: null
    },
    {
        name: 'date created',
        sort: true,
        action: null
    },
    {
        name: '',
        sort: false,
        action: null
    }
]

export const user_management_headers = [
    {
        name: 'name',
        sort: false,
        action: null
    },
    {
        name: 'Email address',
        sort: false,
        action: null
    },
    {
        name: 'Role',
        sort: false,
        action: null
    },
    {
        name: 'Date created',
        sort: true,
        action: null
    },
    {
        name: 'Status',
        sort: false,
        action: null
    },
    {
        name: '',
        sort: false,
        action: null
    },
]

export const communities_headers = [
    {
        name: 'community name',
        sort: false,
        action: null
    },
    {
        name: 'contact person',
        sort: false,
        action: null
    },
    {
        name: 'location (state)',
        sort: false,
        action: null
    },
    {
        name: 'no. of drivers',
        sort: false,
        action: null
    },
    {
        name: 'Date created',
        sort: true,
        action: null
    },
    {
        name: 'Status',
        sort: false,
        action: null
    },
    {
        name: '',
        sort: false,
        action: null
    },
]
export const drivers_headers = [
    {
        name: 'name',
        sort: false,
        action: null
    },
    {
        name: 'email address',
        sort: false,
        action: null
    },
    {
        name: 'phone number',
        sort: false,
        action: null
    },
    {
        name: 'vehicle type',
        sort: false,
        action: null
    },
    {
        name: 'Status',
        sort: false,
        action: null
    },
    {
        name: '',
        sort: false,
        action: null
    },
]