import useMakeRequest from 'hooks/useMakeRequest/useMakeRequest';
import useTryCatch from 'hooks/useTryCatch/useTryCatch';
import { useState } from 'react';
import { login, verifyOtp, forgotPassword, createPassword, validateResetPasswordCode, resendOtp } from 'store/modules/auth/actions';
import useInputValidate from '../useInputValidate/useInputValidate';

export default function useAuth() {
  const { validate } = useInputValidate();
  const { makeRequest } = useMakeRequest();
  const { tryCatch } = useTryCatch();
  const [loading, setLoading] = useState(false);

  const login_user = (formData) => {
    tryCatch(async () => {
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({ action: login(formData), to: `/verify-account` })
        .finally(() => setLoading(false));
    });
  };

  const verify_otp = (userOtp, callback) => {
    tryCatch(async () => {
      setLoading(true);
      makeRequest({ action: verifyOtp(userOtp), callback })
        .finally(() => setLoading(false));
    });
  };

  const forgot_password = async (formData, callback) => {
    tryCatch(async () => {
      // check if email is valid
      if (!validate({ name: 'email', value: formData.email })) return;
      setLoading(true);
      makeRequest({ action: forgotPassword(formData), to: `/verify-account?email=${formData.email}`, callback})
        .finally(() => setLoading(false));
    });
  };

  const create_password = async (formData, callback) => {
    tryCatch(async () => {
      // check if formdata is valid
      if (!validate(formData)) return; // check if formData is valid
      setLoading(true);
      makeRequest({ action: createPassword(formData), callback })
      .finally(() => setLoading(false));
    });
  };

  const validate_reset_code = async (userOtp) => {
    tryCatch(async () => {
      setLoading(true);
      makeRequest({
        action: validateResetPasswordCode(userOtp),
        to: '/reset-password',
      }).finally(() => setLoading(false));
    });
  };

  const resend_otp = async (email, callback) => {
    tryCatch(async () => {
      makeRequest({
        action: resendOtp({email}), callback
      })
    });
  };

  return {
    login_user,
    loading,
    verify_otp,
    forgot_password,
    create_password,
    validate_reset_code,
    resend_otp
  };
}
