import dayjs from "dayjs"
import useRoles from "hooks/fetch/useRoles"
import useUsers from "hooks/fetch/useUsers"
import { useAppDispatch } from "hooks/useReduxHook"
import useTable from "hooks/useTable/useTable"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { setSelectedUser, useUserState } from "store/modules/users"
import { formatDate } from "utils/formatDate"
import { exportData } from "utils/exportData"
import useTryCatch from "hooks/useTryCatch/useTryCatch"
import usePermission from "hooks/usePermission/usePermission"
import { modules } from "constants/module"

export default function useUserManagement() {
    const navigate = useNavigate()
    const { tryCatch } = useTryCatch();
    const { get_users, loading } = useUsers()
    const { export_users, loading: isExportingData } = useUsers()
    const { get_roles_no_limit, loading: loadingRoles } = useRoles()
    const { users } = useUserState()
    const { hasPermission } = usePermission()
    const { userManagement } = modules
    const dispatch = useAppDispatch();
    const [selectedAction, setSelectedAction] = useState("")
    const [allRoles, setAllRoles] = useState([])
    const { params, openFilterModal, closeFilterModal, filterModal, handleSearch, changePage, selectLimit, handleSort } = useTable()
    const [selectedFilters, setSelectedFilters] = useState()
    const [filters, setFilters] = useState({
        role: '',
        status: ''
    })
    const [userDetails, setUserDetails] = useState()
    const [dateSelection, setDateSelection] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection',
        },
    ]);

    const { startDate, endDate } = dateSelection[0]

    const handleFilter = (e) => {
        e.preventDefault()
        setSelectedFilters({
            ...filters,
            fromDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
            toDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
        })
    }

    const handleViewUser = (item) => {
        navigate(`${item?.user_id}`)
        dispatch(setSelectedUser(item));
    }

    const getAllUsers = () => {
        const callback = selectedFilters ? closeFilterModal : null
        const payload = !selectedFilters ? { params } : { params: { ...params, ...selectedFilters } }
        get_users(payload, callback)
    }

    const exportFunc = useCallback(() => {
        export_users()
            .then(res => {
                if (res?.code === 200) {
                    tryCatch(() => {
                        const data = res?.data?.users?.map((item) => {
                            return {
                                "Name": `${item?.first_name} ${item?.last_name}`,
                                "Email Address": item?.email,
                                "Role": item?.role,
                                "Status": item?.status,
                                "Date created": formatDate(item?.created_at)
                            };
                        });
                        exportData(data, "Users")
                    })
                }
            })
    }, [])

    const clearFilters = () => {
        setSelectedFilters()
        setFilters({
            role: '',
            status: ''
        })
        setDateSelection([
            {
                startDate: '',
                endDate: '',
                key: 'selection'
            }
        ])
    }

    const actions = [
        {
            name: "Edit user",
            permission: (userManagement, "Edit user"),
            method: (item) => {
                setSelectedAction("Edit")
                dispatch(setSelectedUser(item));
            }
        },
        {
            name: "View user",
            permission: hasPermission(userManagement, "View users"),
            method: (item) => handleViewUser(item)
        },
        {
            name: "Deactivate user",
            permission: hasPermission(userManagement, "Deactivate user"),
            method: (item) => {
                setUserDetails(item)
                setSelectedAction("Deactivate")
            }
        },
        {
            name: "Activate user",
            permission: hasPermission(userManagement, "Activate user"),
            method: (item) => {
                setUserDetails(item)
                setSelectedAction("Activate")
            }
        }
    ]

    const resetSelectedAction = () => {
        setSelectedAction("")
        setUserDetails()
    }

    useEffect(() => {
        getAllUsers()
    }, [params, selectedFilters])

    useEffect(() => {
        get_roles_no_limit()
            .then(res => {
                tryCatch(() => {
                    if (res?.code === 200) {
                        const roles = res.data?.roles?.map(role => {
                            return { name: role.role_name, value: role.role_name }
                        })
                        setAllRoles(roles)
                    }
                })
            })
    }, [])

    return {
        openFilterModal,
        filters,
        filterModal,
        users,
        setFilters,
        selectedAction,
        setSelectedAction,
        dateSelection,
        isExportingData,
        setDateSelection,
        getAllUsers,
        handleSearch,
        exportFunc,
        changePage,
        loadingRoles,
        handleSort,
        handleFilter,
        userDetails,
        setUserDetails,
        selectLimit,
        resetSelectedAction,
        actions,
        rolesOptions: allRoles,
        selectedFilters,
        clearFilters,
        loading,
        handleViewUser,
        closeFilterModal
    }
}